<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="title">
      <p style="margin-left:164px">Game List</p>
    </div>
    <div style="width: 100%;margin-top: 36px">
      <div v-for="item in games" class="game-item" @click="goInvestment(item)">
        <div style="flex:1;margin: 20px 10px 20px 30px">
          <p class="game-item-name">Team Code {{ item.team_code }}</p>
          <p class="game-item-meeting-name">{{ item.meeting_name }}</p>
          <p class="game-item-meeting-name" style="margin-top: 10px">{{ item.name }}</p>
        </div>
        <img src="../../assets/gamelist_icon.png" style="width: 164px;height: auto">
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import {getGames} from '../../api/sports'
import {getUserId, saveGameInfo} from "../../utils/store";

export default {
  name: "Games",
  data() {
    return {
      games: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    goInvestment(item) {
      let game_id = item._id.$id
      let period = item.period
      saveGameInfo(JSON.stringify(item))

      this.$router.push({
        path: 'game/investment',
        query: {
          id: game_id,
          period: period
        }
      })
    },

    fetchData() {
      this.games = []
      let user_id = getUserId()
      getGames(user_id).then((res) => {
        let result = res.data
        for (var i = 0; i < result.length; i++) {
          this.games.push(result[i])
          //测试使用
          // this.games.push(result[i])
        }
      })
    }
  }
}
</script>

<style scoped>

.game-item-name {
  color: #FE7702;
  font-size: 22px;
  font-weight: bold;
}

.game-item-meeting-name {
  margin-top: 20px;
  color: #666666;
  font-size: 18px;
  line-height: 28px;
}

.game-item {
  cursor: pointer;
  margin: 0 164px 26px 164px;
  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 16px 1px rgba(0, 0, 0, 0.0800);
}

</style>