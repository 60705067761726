<template>
  <div class="view-root" style="height: 100vh;">
    <div class="view-content" style="height: 100vh;display: flex;flex-direction: row;">
      <img src="../../assets/login_img.png" style="width:70vw;max-width: 1341px;height: 100vh;object-fit: cover">
      <div class="login-content">
        <img src="../../assets/login_icon.png" style="width: 366px;height: auto">
        <el-input v-model="user_email" class="el-input" placeholder="Account Email"
                  style="margin-top: 62px"></el-input>
        <el-input v-model="user_pwd" class="el-input" placeholder="Password" style="margin-top: 40px"
                  type="password"></el-input>
        <el-select class="login-select" popper-append-to-body="false" v-model="login_type"
                   placeholder="Identify Type" style="margin-top: 40px">
          <el-option label="Student" value="student"></el-option>
          <el-option label="Adviser" value="contact"></el-option>
        </el-select>
        <el-select class="login-select" popper-append-to-body="false" v-model="project_code"
                   placeholder="FinSports Season" style="margin-top: 40px">
          <el-option v-for="item in projects" :label="item.project_name" :value="item.project_code"></el-option>
        </el-select>
        <el-button class="btn-login" @click="submit" :loading="loading">SIGN IN</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import {getProjects, loginByStudent,loginByContact} from '../../api/sports'
import {setProjectCode, setProjectName, setUserId, setUserName, setUserType,setUserAccountId} from "../../utils/store";
//student:测试账号：123chesi@qq.com impact
//contact:test-adviser@qq.com service
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      user_email: '',
      user_pwd: '',
      project_code: '',
      login_type: '',
      projects: []
    }
  },
  mounted() {
    this.requestData()
  },
  methods: {
    requestData() {
      getProjects().then((res) => {
        this.projects = res.data.data
      })
    },
    submit() {
      if (this.user_email == '') {
        this.$message.error('Please Input Email')
        return
      }
      if (this.user_pwd == '') {
        this.$message.error('Please Input Passwords')
        return
      }
      if (this.login_type == '') {
        this.$message.error('Please Select Identify Type')
        return
      }
      if (this.project_code == '') {
        this.$message.error('Please Select Project')
        return
      }
      this.loading = true
      if (this.login_type == 'student') {
        loginByStudent(this.user_email, this.user_pwd, this.project_code).then((res) => {
          let result = res.data
          this.loading = false
          if (result.code == 0) {
            let project_name = result.data.project_name
            let student_name = result.data.student_name
            let user_id = result.data._id.$id
            setProjectCode(this.project_code)
            setProjectName(project_name)
            setUserId(user_id)
            setUserName(student_name)
            setUserType("student")
            setUserAccountId(result.data.account_id)
            this.$router.replace('/')
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        loginByContact(this.user_email, this.user_pwd, this.project_code).then((res) => {
          let result = res.data
          this.loading = false
          if (result.code == 0) {
            let project_name = result.data.project_name
            let contact_name = result.data.contact_lastName+result.data.contact_givenName
            let user_id = result.data._id.$id
            setProjectCode(this.project_code)
            setProjectName(project_name)
            setUserId(user_id)
            setUserName(contact_name)
            setUserType("contact")
            setUserAccountId(result.data.account_id)
            this.$router.replace('/')
          } else {
            this.$message.error(result.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

.btn-login {
  border-radius: 10px;
  margin-top: 100px;
  background-color: #FE7702;
  cursor: pointer;
  width: 100%;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 130px;
  padding-right: 130px
}

/deep/ .el-input__inner {
  background-color: #EDEFF4;
  height: 60px;
  outline: none;
  border: transparent;
  border-radius: 10px;
  color: #666666;
  font-size: 20px;
}

/deep/ .el-select-dropdown__item {
  color: #666666;
  font-size: 20px;
}

.login-select {
  border-radius: 10px;
  color: #666666;
  font-size: 20px;
  width: 100%;
  height: 60px;
  background-color: #EDEFF4;
  outline: none;
  padding: 0 15px 0 0px;
}
</style>
