<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>
      <div class="data-content-data">
        <p class="application-title">Base Application Info</p>
        <p class="application-base" style="margin-top: 32px">Registered
          Season：{{ applicationInfo.project_info.project_name }}</p>
        <div class="flex-row">
          <p class="application-base" style="flex: 1">Payment Status：{{ applicationInfo.application_status }}</p>
          <p class="application-base" style="flex: 1">Registration Channel：{{ applicationInfo.application_channel }}</p>
        </div>
        <div class="flex-row" v-if="applicationInfo.application_status!=='无需缴费'">
          <p class="application-base" style="flex: 1">
            Registration Fee：{{
              applicationInfo.application_payment_amount ? applicationInfo.application_payment_amount + ' (' + applicationInfo.application_payment_currency + ')' : 'No Payment Information Available'
            }}</p>
        </div>

        <div class="flex-row" v-if="applicationInfo.application_status=='已缴费'">
          <p class="application-base" style="flex: 1">
            Payment Policy Check and Agreement：{{
              check_time + '  Checked and Agreed:' + '\n' + (applicationInfo.refund_policy_check ? applicationInfo.refund_policy_check.content : 'No Payment Information Available')
            }}</p>
        </div>
        <div v-if="applicationInfo.application_status=='待缴费'">
          <p class="go-pay" @click="doPay">Pay Now</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack";
import {getProjectCode, getUserId} from "../../utils/store";
import {getApplicationInfoByID} from '../../api/sports'
import {formatDate} from "../../utils/date";
import PayPal from 'vue-paypal-checkout'
//// paypal 测试账号  sb-vvzgb17842176@personal.example.com  密码 7F=Ub81o
export default {
  name: "ApplicationInfo",
  components: {
    ViewBack,
    PayPal
  },
  data() {
    return {
      check_time: '',
      title: 'Application Info',
      applicationInfo: {
        project_info: {},
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      getApplicationInfoByID(getUserId(), getProjectCode()).then((res) => {
        let result = res.data.data
        this.applicationInfo = result.application_info
        // this.applicationInfo.application_status = '待缴费'
        if (this.applicationInfo.application_status !== '无需缴费') {
          var create_time = this.applicationInfo.refund_policy_check.create_time
          this.check_time = formatDate(new Date(create_time * 1000), 'yyyy-MM-dd hh:mm')
        }
      })
    },
    doPay() {
      this.$router.push('application/pay')
    }
  }
}
</script>

<style scoped lang="less">

.application-title {
  color: #FE7702;
  font-size: 24px;
  font-weight: bold;
}

.application-base {
  color: #666666;
  font-size: 18px;
  margin-bottom: 24px;
}

.application-policy {
  color: #666666;
  font-size: 16px;
  line-height: 28px;
}

.go-pay {
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 18px;
  font-weight: normal;
  color: #FFFFFF;
}
</style>
