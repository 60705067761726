<template>
  <div class="data-content" style="height: 100%;overflow-y: auto">

    <div style="width: 100%;padding-left: 100px;padding-right: 100px;margin-top: 100px;box-sizing: border-box">

      <div style="text-align: center;color: #FE7702;font-size: 24px">
        Upcoming FinSports Meetings
      </div>

      <div class="list">
        <div v-for="item in dataList" class="list-item" @click="showStudentApps(item)">
          <div class="item-avatar-container">
            <img v-if="item.avatar_pic_address" :src="'http://'+item.avatar_pic_address"
                 style="width: 146px;height: 146px;object-fit: cover">
            <img v-else src="../../assets/meeting_default_avatar.png"
                 style="width: 100px;height: 100px;object-fit: cover">
          </div>
          <div style="margin-left: 15px;flex: 1" class="flex-column-left">
            <p class="meeting-name">{{ item.meeting_name }}</p>
            <div v-if="item.meeting_level && item.meeting_level !=''">
              <p class="meeting-level">{{ item.meeting_level }}</p>
            </div>
            <p class="meeting-date">{{ item.format_time }}</p>


          </div>
        </div>

      </div>


    </div>

    <!--    <img src="../../assets/announcement_icon.png" style="width: 300px;height:auto;margin-top: 50px">-->
    <!--    <p class="title">最新公告</p>-->
    <!--    <p class="content">-->
    <!--      2020-2021学年，FinSports中国区校内站阶段备赛资料为纸质版，即官方指南（Official Guide, OG）。官方指南内包含-->
    <!--      校内站考察的所有知识点，因此，校内站准备建议以官方指南为主，集中掌握核心知识点。 纸质版官方指南将在学校全部-->
    <!--      完成报名缴费后统一寄送至学校，请选手到学校指导老师处领取。 经济学考试形式为材料题，即根据试卷中给出的经济学-->
    <!--      知识点、概念或理论的讲解材料答题，考察临场的理解能力、学习能力和逻辑能力。选手不需要提前进行针对性的备赛。-->
    <!--      线上答疑功能全程开放，选手在准备过程中若有任何疑问，可随时通过资源中心内的消息功能提问。-->
    <!--    </p>-->
  </div>
</template>

<script>
import '../../assets/common/common.css'
import {getFIMeetings} from '../../api/sports'
import {formatMonthToEng} from '../../utils/date'

import {getUserType} from '../../utils/store'

export default {
  name: "Announcement",
  data() {
    return {
      dataList: [],
      userType : getUserType()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getFIMeetings().then((res) => {
        let result = res.data
        this.dataList = result.map((item) => {
          var format_time = ''
          if (item.meeting_end_time && item.meeting_begin_time) {
            let meeting_begin_time = item.meeting_begin_time
            let meeting_end_time = item.meeting_end_time
            format_time = this.formatMeetingTime(meeting_begin_time, meeting_end_time)
          }
          item['format_time'] = format_time
          return item
        })
      })
    },
    formatMeetingTime(begin_time, end_time) {
      let begin_date = new Date(begin_time * 1000)
      let begin_month = begin_date.getMonth() + 1

      let end_date = new Date(end_time * 1000)
      let end_month = end_date.getMonth() + 1

      if (begin_date.getFullYear() == end_date.getFullYear()) {

        if (begin_month == end_month) {

          return this.getEngMonth(begin_month) + ' ' + begin_date.getDate() + ' - ' + end_date.getDate() + ', ' + begin_date.getFullYear()

        } else {

          return this.getEngMonth(begin_month) + ' ' + begin_date.getDate() + ' - ' + this.getEngMonth(end_month) + ' ' + end_date.getDate() + ', ' + begin_date.getFullYear()

        }
      } else {

        return this.getEngMonth(begin_month) + ' ' + begin_date.getDate() + ', ' + begin_date.getFullYear() + ' - ' + this.getEngMonth(end_month) + ' ' + end_date.getDate() + ', ' + end_date.getFullYear()

      }
    },
    getEngMonth(month) {
      return formatMonthToEng(month)
    },
    showStudentApps(item){
      if (this.userType == 'contact'){
        console.log(11111,item)

        this.$router.push({
          path: 'studentApps',
          query: {
            meeting_id: item._id.$id
          }
        })
      }
    }
  }
}
</script>

<style scoped>

.meeting-date {
  margin-top: 20px;
  color: #FE7702;
  font-size: 16px;
}

.meeting-name {
  color: #333333;
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
}

.meeting-level {
  max-width: 160px;
  display: inline-block;
  margin-top: 22px;
  text-align: center;
  padding: 4px 12px;
  color: #583518;
  font-size: 16px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #583518;
}

.item-avatar-container {
  text-align: center;
  width: 148px;
  height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #FE7702;
}

.list {
  margin-top: 30px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-gap: 20px;
}

.list-item {
  box-shadow: 0px 5px 16px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 8px 8px;
  background-color: white;
  padding: 28px 20px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}


::-webkit-scrollbar {
  display: none;
}

.title {
  margin-top: 50px;
  font-size: 28px;
  font-weight: normal;
  color: #FD7702;
}

.content {
  margin-bottom: 40px;
  margin-top: 40px;
  box-sizing: border-box;
  width: 100%;
  font-weight: normal;
  line-height: 60px;
  color: #583518;
  font-size: 20px;
  padding: 0 154px;
}

</style>
