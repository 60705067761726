<template>
  <div>
    <div style="text-align: left;width: 100%;display: flex;flex-direction: row;align-items: center">
      <img src="../assets/img_back.png" style="cursor: pointer" @click="goBack">
      <p style="color: #583518;font-size: 15px;margin-left: 20px;font-weight: bold">{{ title }}</p>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'

export default {
  name: "ViewBack",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>