import Vue from 'vue'
import VueRouter from 'vue-router'


import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'
import Announcement from '../views/announcement/Announcement.vue'
import Dashbord from '../views/dashbord/Dashbord.vue'
import ApplicationInfo from '../views/application/ApplicationInfo.vue'
import PayConfirm from '../views/application/PayConfirm.vue'
import Passwords from '../views/dashbord/Passwords.vue'
import PersonalInfo from '../views/dashbord/PersonalInfo.vue'
import Material from '../views/material/Material.vue'
import Games from '../views/games/Games.vue'
import Investment from '../views/games/Investment.vue'
import InvestmentDetail from '../views/games/InvestmentDetail.vue'
import MessageCenter from '../views/messages/MessageCenter.vue'
import AppApply from '../views/appapply.vue'
import StudentApps from '../views/announcement/studentApps.vue'

import {getUserId} from "@/utils/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: Login,
        component: Login
    },
    {
        path: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true,
            title: '首页'
        }
    },
    {
        path: '/appform',
        name: 'appform',
        component: () => import('../views/appform.vue')
    },
    {
        path: '/appapply',
        name: 'appapply',
        component: AppApply,
        meta: {
            title: '学生注册'
        }
    },
    {
        path: '/home',
        name: Home,
        component: Home,
        meta: {
            requiresAuth: true,
            title: '首页'
        },
        redirect: '/home/news',
        children: [
            {
                path: 'news',
                name: Announcement,
                component: Announcement,
                meta: {
                    requiresAuth: true,
                    title: 'News'
                },
            },
            {
                path: 'panel',
                name: Dashbord,
                component: Dashbord,
                meta: {
                    requiresAuth: true,
                    title: 'Panel'
                },
            },
            {
                path: 'panel/application',
                name: ApplicationInfo,
                component: ApplicationInfo,
                meta: {
                    requiresAuth: true,
                    title: 'ApplicationInfo'
                },
            },
            {
                path: 'studentApps',
                name: StudentApps,
                component: StudentApps,
                meta: {
                    requiresAuth: true,
                    title: 'StudentApps'
                },
            },
            {
                path: 'panel/application/pay',
                name: PayConfirm,
                component: PayConfirm,
                meta: {
                    requiresAuth: true,
                    title: 'PayConfirm'
                },
            },
            {
                path: 'panel/passwords',
                name: Passwords,
                component: Passwords,
                meta: {
                    title: 'Passwords'
                },
            },
            {
                path: 'panel/personal',
                name: PersonalInfo,
                component: PersonalInfo,
                meta: {
                    requiresAuth: true,
                    title: 'Personal'
                },
            },
            {
                path: 'references',
                name: Material,
                component: Material,
                meta: {
                    requiresAuth: true,
                    title: 'References'
                },
            },
            {
                path: 'game',
                name: Games,
                component: Games,
                meta: {
                    requiresAuth: true,
                    title: 'Games'
                },
            },
            {
                path: 'game/investment',
                name: Investment,
                component: Investment,
                meta: {
                    requiresAuth: true,
                    title: 'Investment Game'
                },
            },
            {
                path: 'game/investment/detail',
                name: InvestmentDetail,
                component: InvestmentDetail,
                meta: {
                    requiresAuth: true,
                    title: 'Investment Detail'
                },
            },
            {
                path: 'messages',
                name: MessageCenter,
                component: MessageCenter,
                meta: {
                    requiresAuth: true,
                    title: 'Messages'
                },
            },

        ]
    },
]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router
