<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>
      <div style="display: flex;flex-direction: column;margin: 30px auto 0px;width: 40%">
        <div style="text-align: left">
          <p class="pwd-name">Change Password</p>
          <el-form label-position="top" :model="pwd_info" :rules="rules" ref="pwd_info" style="margin-top: 30px">
            <el-form-item label="Original Password" prop="originalPwd">
              <el-input v-model="pwd_info.originalPwd" type="password"
                        placeholder="Please Input Original Password"></el-input>
            </el-form-item>
            <el-form-item label="New Password" prop="newPwd">
              <el-input v-model="pwd_info.newPwd" type="password" placeholder="Please Input Your New Password"></el-input>
            </el-form-item>
            <el-form-item label="Confirm New Password" prop="againPwd">
              <el-input v-model="pwd_info.againPwd" type="password"
                        placeholder="Please Confirm Your New Password"></el-input>
            </el-form-item>
          </el-form>

          <p class="pwd-submit" @click="submitForm('pwd_info')">Submit</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack"
import {changePassword} from '../../api/sports'
import {getUserId} from "../../utils/store";

export default {
  name: "Passwords",
  components: {
    ViewBack
  },
  data() {
    return {
      title: 'Change Password',
      pwd_info: {
        originalPwd: '',
        newPwd: '',
        againPwd: ''
      },
      rules: {
        originalPwd: [{required: true, message: 'Please Input Original Password', trigger: 'blur'},],
        newPwd: [{required: true, message: 'Please Input New Password', trigger: 'blur'},],
        againPwd: [{required: true, message: 'Please Again Input New Password', trigger: 'blur'},]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changePassword(getUserId(), this.pwd_info.originalPwd, this.pwd_info.newPwd, this.pwd_info.againPwd).then((res) => {
            let result = res.data
            if (result.code == 0) {
              this.$message.success('Change Success')
              this.$router.go(-1)
            } else {
              this.$message.error(result.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.pwd-name {
  color: #FE7702;
  font-size: 24px;
  font-weight: bold;
}

.pwd-submit {
  cursor: pointer;
  margin-top: 50px;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 18px;
  font-weight: normal;
  color: #FFFFFF;
}

</style>