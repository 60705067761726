<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>

      <div class="data-content-data">
        <el-select v-model="select_period" class="customSelect" popper-append-to-body="false"
                   @change="handlePeriodChange">
          <el-option v-for="period in PERIODS" :label="period.label" :value="period.value"></el-option>
        </el-select>

        <div style="margin-top: 20px;" class="flex-row">
          <div :class="selectTab==1?'tab tab-selected':'tab'" @click="changeTab(1)">{{ tabLeftName }}</div>
          <div :class="selectTab==2?'tab tab-selected':'tab'" style="margin-left: 20px" @click="changeTab(2)">
            {{ tabRightName }}
          </div>
        </div>
        <div v-if="selectTab==1">
          <CurrentInvestmentDecision style="margin-top: 20px" :total_assets="periodAssets.total_assets"
                                     :expected_closing_cash="canUseMoney" :current_available_cash="currentUseMoney"/>
          <div v-if="!uploadDecisions" class="add-investment-division" @click="doShowSelectInvestmentDialog">
            + Add an investment decision
          </div>
          <div style="margin-top: 30px">
            <InvestmentDecision v-for="item in investment_decisions" :investment_decision="item"
                                @editDecision="editDecision"/>
          </div>
          <div v-if="!uploadDecisions" class="submit-decisions" @click="submitDecisions">Submit Decisions</div>
        </div>

        <div v-if="selectTab==2">
          <CurrentInvestmentAssets style="margin-top: 20px" :total_assets="periodAssets.total_assets"
                                   :salary="salary" :salaryReason="salaryReason"/>

          <div style="margin-top: 30px">
            <PeriodAssetsDecision v-for="item in periodAssetsDecisions" :investment_decision="item"
                                  @goInstrumentDetail="goInstrumentDetail" :period="period" :select_period="select_period"/>
          </div>


        </div>
      </div>
    </div>

    <el-dialog :visible.sync="showSelectInvestmentDialog" :modal="true" :show-close="false"
               :modal-append-to-body="true"
               custom-class="showSelectInvestmentStyle">
      <SelectInvestmentList ref="selectInvestment"
                            @closeDialog="showSelectInvestmentDialog=false"
                            @confirmSelectInvestment="confirmSelectInvestment"/>
    </el-dialog>

    <el-dialog :visible.sync="showAddInvestmentDialog" :modal="true" :show-close="false"
               :modal-append-to-body="true"
               custom-class="showSelectInvestmentStyle">
      <AddInvestment @closeDialog="showAddInvestmentDialog=false"
                     @saveData="saveInvestment" ref="addInvestment"/>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {getGame, getPeriodAssets, getPeriodInstrumentDeals, updatePeriodInstrumentDeals} from '../../api/sports'
import {getGameInfo, getUserId} from "../../utils/store";
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack";
import InvestmentDecision from '../../components/InvestmentDecision'
import CurrentInvestmentDecision from '../../components/CurrentInvestmentDecision'
import SelectInvestmentList from "../../components/SelectInvestmentList";
import AddInvestment from "../../components/AddInvestment";
import CurrentInvestmentAssets from "../../components/CurrentInvestmentAssets";
import PeriodAssetsDecision from "../../components/PeriodAssetsDecision";

export default {
  name: "Investment",
  components: {
    ViewBack,
    InvestmentDecision,
    CurrentInvestmentDecision,
    SelectInvestmentList,
    AddInvestment,
    CurrentInvestmentAssets,
    PeriodAssetsDecision
  },
  beforeDestroy() {
    Cookies.set('selectedTab', this.selectTab)
  },
  data() {
    return {
      title: 'Investment Game',
      user_id: getUserId(),
      PERIODS: [],
      game_id: this.$route.query.id,
      period: 1,
      last_select_period: '',
      select_period: '',
      gameInfo: {},
      periodAssets: {},
      periodAssetsDecisions: [],
      selectTab: 1,
      investment_decisions: [],
      showSelectInvestmentDialog: false,
      showAddInvestmentDialog: false,
      selectedInstrument: undefined,

      tabLeftName: 'Current Decisions',
      tabRightName: 'Current Assets',

      salary: '',
      salaryReason: '',
      canUseMoney: 0,
      currentUseMoney: 0,

      uploadDecisions: false
    }
  },
  mounted() {
    for (var i = 1; i <= 10; i++) {
      this.PERIODS.push({
        label: 'Period ' + i,
        value: i
      })
    }
    let temp_period = '' + this.$route.query.period
    this.period = parseInt(temp_period)
    this.select_period = this.period
    this.last_select_period = this.select_period
    this.requestPeriodInstrumentDeals(this.select_period)
    let tab = Cookies.get('selectedTab')
    if (tab != null && tab != '') {
      this.selectTab = tab
    }
  },
  methods: {
    goInstrumentDetail(instrument) {
      this.$router.push({
        path: '/home/game/investment/detail',
        query: {
          game_id: this.game_id,
          period: this.period,
          instrument_id: instrument.instrument_id
        }
      })
    },
    editDecision(instrument_decision) {
      this.showAddInvestmentDialog = true
      this.$nextTick(() => {
        this.$refs.addInvestment.fetchData(instrument_decision, this.periodAssets, this.canUseMoney, 'edit')
      })
    },
    doShowSelectInvestmentDialog() {
      this.showSelectInvestmentDialog = true
      this.$nextTick(() => {
        this.$refs.selectInvestment.fetchData(this.game_id, this.period, this.investment_decisions)
      })
    },
    confirmSelectInvestment(selectedInvestment) {
      this.selectedInstrument = selectedInvestment
      this.showSelectInvestmentDialog = false
      this.showAddInvestmentDialog = true
      this.$nextTick(() => {
        this.$refs.addInvestment.fetchData(this.selectedInstrument, this.periodAssets, this.canUseMoney, 'add')
      })
    },
    saveInvestment(params) {
      let data = params.data
      if (params.selectType == 'add') {
        this.investment_decisions.push(data)
      } else {
        var editIndex = 0
        for (var i = 0; i < this.investment_decisions.length; i++) {
          if (this.investment_decisions[i].instrument_id == data.instrument_id) {
            editIndex = i
          }
        }
        if (params.selectType == 'delete') {
          this.investment_decisions.splice(editIndex, 1)
        } else {
          this.investment_decisions[editIndex] = data
        }
      }
      this.showAddInvestmentDialog = false
      this.fetchData()
    },
    fetchData(period) {
      var gameInfo = JSON.parse(getGameInfo())
      let wage_arr = gameInfo['wage_arr']
      let wage_reason_arr = gameInfo['wage_reason_arr']
      this.salary = wage_arr[parseInt(period)]
      this.salaryReason = wage_reason_arr[parseInt(period)]
      getPeriodAssets(this.user_id, this.game_id, period).then((res) => {
        let result = res.data
        if (result != null) {
          this.parsePeriodAssetsData(result)
        }
      })
    },
    requestPeriodInstrumentDeals(period) {
      this.uploadDecisions = false
      this.investment_decisions = []
      getPeriodInstrumentDeals(this.user_id, this.game_id, period).then((res) => {
        if (res.data) {
          //已经上传过的不可以编辑
          let deal_array = res.data.deal_array
          for (var i = 0; i < deal_array.length; i++) {
            let deal = deal_array[i]
            deal['can_edit'] = 1
            this.investment_decisions.push(deal)
          }
          if (this.investment_decisions.length > 0) {
            this.uploadDecisions = true
          }

          if (this.select_period == this.period) {
            this.tabLeftName = 'Current Decisions'
            this.tabRightName = 'Current Assets'
          } else {
            this.tabLeftName = 'Period ' + this.select_period + ' Decisions'
            this.tabRightName = 'Period ' + this.select_period + ' Assets'
          }
        }
        this.fetchData(period)
      })
    },
    handlePeriodChange(e) {
      let temp_select_period = e
      getGame(this.game_id).then((res) => {
        let temp_period = res.data.period
        this.period = temp_period
        if (temp_select_period > temp_period) {
          this.select_period = this.last_select_period
          this.$message.error('Period ' + temp_select_period + " hasn't started")
        } else {
          this.last_select_period = this.select_period
          this.requestPeriodInstrumentDeals(this.select_period)
        }
      })
    },
    parsePeriodAssetsData(result) {
      this.periodAssets = result
      this.periodAssetsDecisions = []
      var keys = Object.keys(result.assets_array)
      for (const k in keys) {
        var keyStr = keys[k]
        var decision = {};
        var info = result.instrumentInfo[keyStr]
        decision.instrument_id = info.instrument_id
        decision.name = info.instrument_name
        decision.price = info.price
        decision.change_percent = info.change_percent
        if (info.change_percent.indexOf("+") != -1) {
          decision.change_percent_add = true
        } else {
          decision.change_percent_add = false
        }
        decision.num = result.assets_array[keyStr]
        decision.allPrice = parseFloat(decision.num * (decision.price / 100)).toFixed(2);
        this.periodAssetsDecisions.push(decision)
      }
      //new data 界面显示
      var canUseMoney = result.cash_balance
      var currentStr = result.cash_balance
      this.currentUseMoney = parseFloat(currentStr).toFixed(2);

      for (var i = 0; i < this.investment_decisions.length; i++) {
        var info = this.investment_decisions[i]
        if (info.type == 'buy') {
          canUseMoney = canUseMoney - Number(info.money)
        } else {
          canUseMoney = canUseMoney + Number(info.money)
        }
      }
      this.canUseMoney = parseFloat(canUseMoney).toFixed(2);
    },
    submitDecisions() {

      if (this.select_period == 6) {
        this.$message.error('Error')
        return
      }
      if (this.canUseMoney < 0) {
        this.$message.error('Expected Closing Cash Not Minus')
        return
      }
      let that = this
      if (this.uploadDecisions) {
        this.$alert('You didn\'t make any investment decision in this period. Are you sure to submit?', 'Notice', {
          confirmButtonText: '确定',
          callback: action => {
            if (action == 'confirm') {
              that.doUpdatePeriodInstrumentDeals()
            }
          }
        });
      } else {
        this.$alert('Are you sure to submit the decision(s) for the current period?', 'Notice', {
          confirmButtonText: '确定',
          callback: action => {
            console.log('action:' + action)
            if (action == 'confirm') {
              //上传
              that.doUpdatePeriodInstrumentDeals()
            }
          }
        });
      }
    },
    doUpdatePeriodInstrumentDeals() {
      updatePeriodInstrumentDeals(this.user_id, this.game_id, this.period, this.investment_decisions).then((res) => {
        this.$message.success('上传成功！')
        this.requestPeriodInstrumentDeals(this.select_period)
      })
    },
    changeTab(value) {
      this.selectTab = value
    },
  }
}
</script>


<style>
.showSelectInvestmentStyle {
  width: 60%;
  height: 80vh;
  overflow-y: auto;
  text-align: center;
  background-color: white;
  padding: 0px;
  box-shadow: 0px 0px 0px 0px transparent;
}

</style>
<style scoped lang="less">

/deep/ .el-dialog__header {
  padding: 0px;
}

/deep/ .el-dialog__body {
  padding: 0 0 30px 0;
}


.customSelect {
  border-radius: 2px;
  border-color: #CCCCCC;
  border-width: 1px;
  border-style: solid;
  color: #666666;
  font-size: 20px;
  width: 264px;
  height: 40px;
  background-color: white;
  outline: none;
}

/deep/ .el-input__inner {
  outline: none;
  border: transparent;
}

.tab {
  cursor: pointer;
  width: 200px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  border: 1px solid #FE7702;
  border-radius: 2px 2px 2px 2px;
  background-color: white;
  color: #FE7702;
}

.tab-selected {
  border-color: transparent;;
  background: #FFE9D0;
}

.submit-decisions {
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  width: 200px;
  height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 18px;
  font-weight: normal;
  color: #FFFFFF;
}

.add-investment-division {
  cursor: pointer;
  margin-top: 40px;
  text-align: center;
  line-height: 60px;
  width: 360px;
  height: 60px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border-width: 2px;
  border-color: #FE7702;
  border-style: dashed;
  font-size: 20px;
  font-weight: bold;
  color: #FE7702;
}

</style>