import Cookies from 'js-cookie'

const USER_ID = 'user_id'
const ACCOUNT_ID = 'account_id'
const USER_NAME = 'user_name'
const USER_TYPE = 'user_type'
const PROJECT_NAME = 'project_name'
const PROJECT_CODE = 'project_code'

const USER_TYPE_STUDENT = 'student'
const USER_TYPE_ADMIN = 'admin'

export function setProjectCode(project_code) {
    Cookies.set(PROJECT_CODE, project_code)
}

export function getProjectCode() {
    return Cookies.get(PROJECT_CODE)
}

export function saveGameInfo(game_info) {
    Cookies.set('GameInfo', game_info)
}

export function getGameInfo() {
    return Cookies.get('GameInfo')
}

export function setProjectName(project_name) {
    Cookies.set(PROJECT_NAME, project_name)
}

export function getProjectName() {
    return Cookies.get(PROJECT_NAME)
}

export function setUserType(user_type) {
    Cookies.set(USER_TYPE, user_type)
}

export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function setUserAccountId(account_id) {
    Cookies.set(ACCOUNT_ID, account_id)
}
export function getUserAccountId() {
    return Cookies.get(ACCOUNT_ID)
}

export function clearUser() {
    Cookies.remove(USER_TYPE)
    Cookies.remove(USER_ID)
    Cookies.remove(ACCOUNT_ID)
    Cookies.remove(USER_NAME)
    Cookies.remove(PROJECT_NAME)
    Cookies.remove('GameInfo')
    Cookies.remove(PROJECT_CODE)
}