<template>
  <div class="data-content" style="height: 100%;overflow-y: auto">
    <div style="width: 100%;padding-left: 100px;padding-right: 100px;margin-top: 100px;box-sizing: border-box">

      <div class="flex-row" style="margin-bottom: 20px">
        <p style="flex: 1;color: #583518;font-size: 24px;font-weight: bold">我的消息</p>
        <p class="msg-send-container" @click="showSendMsgDialog">+新消息</p>
      </div>
      <el-empty
          v-if="!datas.length"
          description="No Messages Yet"
          :image-size="220"
          image="https://mainbucket.learningfirst.cn/noMessage.png"
      ></el-empty>
      <div
          v-for="(item, index) in datas"
          :key="index"
          class="msg-item">
        <div style="color: #FE7702;font-size: 22px" v-if="item.type=='general'">
          <span>Sent</span> <span style="color: #666666;font-size: 16px">{{ getTime(item.create_time * 1000) }}</span>
        </div>
        <div style="color: #FE7702;font-size: 22px" v-else>
          <span>Reply</span> <span style="color: #666666;font-size: 16px">{{ getTime(item.create_time * 1000) }}</span>
        </div>
        <div style="color: #666666;font-size: 18px;margin-top: 20px">
          <div>{{ item.message_content }}</div>
          <div style="margin-top: 20px;" v-if="item.reply_content">Reply:{{ item.reply_content }}</div>
        </div>
      </div>
    </div>
    <el-dialog
        width="40%"
        title='新的消息'
        :visible.sync="dialogVisible"
        custom-class="dialog_class"
        close-on-click-modal
        close-on-press-escape
    >
      <div style="width: 100%;text-align: center;padding: 0 30px;box-sizing: border-box">
        <el-input rows="8" v-model="content" placeholder="Please input your message" type="textarea"></el-input>
        <div style="margin: 30px auto 0px;width: 100%;text-align: center">
          <p class="btn-send-msg" @click="sendMessage">发布新的提问</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import {getMyMessages, sendMessage} from '../../api/sports'
import {getProjectName, getUserId} from '../../utils/store'

export default {
  data() {
    return {
      datas: [],
      dialogVisible: false,
      content: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    showSendMsgDialog() {
      this.content = ''
      this.dialogVisible = true
    },

    getTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      return y + '.' + m + '.' + d
    },
    fetchData() {
      getMyMessages(getUserId(), getProjectName()).then((res) => {
        if (res.data.code === 0) {
          this.datas = res.data.data
        }
      })
    },
    sendMessage() {
      sendMessage(getUserId(), getProjectName(), this.content, 'general', '').then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.fetchData()
          this.datas = res.data.data
          this.dialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.dialog_class.el-dialog {
  border-radius: 15px !important;
  background: #FFFFFF;
}

.msg-item {
  position: relative;
  background: #FFFFFF;
  width: 100%;
  padding: 24px 30px;
  box-sizing: border-box;
  box-shadow: 0px 5px 16px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 0px 0px;
}

.btn-send-msg {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 16px;
  font-weight: normal;
  color: #FFFFFF;
}

.msg-send-container {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  width: 120px;
  height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 20px;
  font-weight: normal;
  color: #FFFFFF;
}

</style>
