<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>
      <div class="data-content-data">
        <div v-for="item in dataList" class="item">
          <div class="flex-row" style="width: 100%;align-items: center">
            <p class="period">Period {{ item.period }}</p>
            <p style="color: #666666;font-size: 20px" v-if="item.period !=1"> Change from previous period</p>
          </div>
          <div class="flex-row" style="width: 100%;margin-top: 20px;align-items: center">
            <p style="color: #F40000;font-size: 36px;flex: 1"> ￥{{ formatPrice(item.price / 100) }}</p>
            <div style="display: flex;flex-direction: column" v-if="item.period != 1">
              <p style="color: #666666;font-size: 0.832vw ">{{ item.change_percent }}</p>
              <div class="reasons-for-change"
                   style=";align-items:center;display: flex;flex-direction: row;margin-top: 5px">
                <p style="cursor: pointer" @click="showChange(item.change_reason)"> Check reasons for change</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack";
import {getInstrumentLatestPeriods} from '../../api/sports'
import {addCommaToThousands} from '../../utils/NumberUtils'

export default {
  name: "InvestmentDetail",
  components: {
    ViewBack,
  },
  data() {
    return {
      dataList: [],
      game_id: this.$route.query.game_id,
      period: this.$route.query.period,
      instrument_id: this.$route.query.instrument_id,
      title: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    },
    showChange(info) {
      this.$alert()
      this.$alert(info, '提示', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
    },
    fetchData() {
      getInstrumentLatestPeriods(this.game_id, this.instrument_id, this.period).then((res) => {
        let result = res.data
        if (result) {
          //   //1 涨 0跌
          for (var i = 0; i < result.length; i++) {
            var info = result[i]
            this.title = info.instrument_info.name
            if (i == (result.length - 1)) {
              info.change = 0
              info.type = 1
            } else {
              var changeinfo = result[i + 1]
              var changePrice = parseInt(changeinfo.price)
              var infoPrice = parseInt(info.price)
              if (infoPrice - changePrice >= 0) {
                info.type = 1
                info.change = parseFloat(((infoPrice - changePrice) / changePrice) * 100).toFixed(2);
              } else {
                info.type = 0
                info.change = parseFloat(((changePrice - infoPrice) / changePrice) * 100).toFixed(2);
              }
            }
            this.dataList.push(info)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

.reasons-for-change {
  box-sizing: border-box;
  background-color: #FF7930;
  border-radius: 2px;
  padding: 8px 10px 8px 20px;
  color: white;
  font-size: 0.832vw;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 20px 20px 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1600);
  border-radius: 2px 2px 2px 2px;
  margin-bottom: 20px;
}

.period {
  flex: 1;
  color: #573518;
  font-size: 24px;
  font-weight: bold;
}
</style>