<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>
      <div style="width: 100%;padding: 40px;box-sizing: border-box">
        <section>
          <div class="student-info">
            <div style="width: 115px;height: 115px;border: 1px solid #FE7702;border-radius: 50%">
              <img src="../../assets/default_avatar.png" style="width: 115px;height: 115px">
            </div>
            <div style="flex: 1;margin-left: 20px;margin-right: 20px" class="flex-column-left">
              <p style="color: #333333;font-size: 18px;font-weight: bold">
                {{ personalInfo.student_lastName + personalInfo.student_givenName }}</p>
              <p style="color: #666666;font-size: 16px;margin-top: 10px">{{ personalInfo.student_email }}</p>
              <!--              <p style="color: #666666;font-size: 16px;margin-top: 10px">Class XX</p>-->
              <p style="color: #666666;font-size: 16px;margin-top: 10px">{{ personalInfo.account_name }}</p>
            </div>

            <div style="text-align: center">
              <p style="color: #583518;font-size: 16px">注册年份</p>
              <p style="color: #FE7702;font-size: 30px;margin-top: 5px">{{ latest_register_time }}</p>
            </div>
            <div style="width: 1px;background-color: #EBDED3;height: 64px;margin-left: 48px;margin-right: 48px"></div>
            <div style="text-align: center">
              <p style="color: #583518;font-size: 16px">积分</p>
              <p style="color: #FE7702;font-size: 30px;margin-top: 5px">0</p>
            </div>
            <div style="width: 1px;background-color: #EBDED3;height: 64px;margin-left: 48px;margin-right: 48px"></div>
            <div style="text-align: center">
              <p style="color: #583518;font-size: 16px">场次</p>
              <p style="color: #FE7702;font-size: 30px;margin-top: 5px">{{ game_count }}</p>
            </div>

          </div>
        </section>
        <div style="margin-top: 40px" class="flex-row">
          <p class="personal-tab" :style="{'background-color':selectedTab==0?'#FFE9D0':'#ffffff'}"
             @click="selectedTab=0">参赛纪录</p>
          <p class="personal-tab" :style="{'background-color':selectedTab==1?'#FFE9D0':'#ffffff'}"
             @click="selectedTab=1">基本信息</p>
        </div>

        <div v-if="selectedTab==0" style="margin-top: 40px">

          <el-table :data="gameList" :border="true" :header-cell-style="{background:'#EFEFEF',color:'#373737'}"
                    v-if="gameList.length>0">
            <el-table-column prop="game_name" label="Game名称"></el-table-column>
            <el-table-column prop="game_level" label="级别"></el-table-column>
            <!--            <el-table-column prop="time" label="Game时间"></el-table-column>-->
            <el-table-column prop="game_rank" label="排名"></el-table-column>
            <el-table-column prop="score" label="成绩"></el-table-column>
          </el-table>

          <div v-if="gameList.length<=0" style="text-align: center">
            <img src="../../assets/no_game.png">
            <p style="color: #666666;font-size: 24px;margin-top: 20px">暂无数据</p>

          </div>

        </div>

        <div v-if="selectedTab==1" style="margin-top: 40px">
          <section>
            <p class="personal-title">Basic Information</p>
            <div class="flex-row" style="margin-top: 40px">
              <p style="flex: 1">
                <label class="personal-content-title">Last Name in Native Language</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_lastName" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <p style="flex: 1">
                <label class="personal-content-title">Given Name in Native Language</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_givenName" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <div style="flex: 1">
                <label class="personal-content-title">Gender</label>
                <div style="margin-top: 10px;height: 40px;line-height: 40px;">
                  <p class="personal-content-title" style="display: inline-block;cursor: pointer"
                     @click="selectGender('男')"><img
                      :src="getGenderIcon('男')">
                    Male
                  </p>
                  <p class="personal-content-title" style="margin-left: 25px;display: inline-block;cursor: pointer"
                     @click="selectGender('女')"><img
                      :src="getGenderIcon('女')">
                    Female
                  </p>
                </div>


              </div>
            </div>
            <div class="flex-row" style="margin-top: 20px">
              <p style="flex: 1">
                <label class="personal-content-title">Last Name in English</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_lastName_pinyin" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <p style="flex: 1">
                <label class="personal-content-title">Given Name in English</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_givenName_pinyin" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <div style="flex: 1">
                <label class="personal-content-title">Expected Year of High School Graduation</label>
                <el-select style="margin-top: 10px" v-model="personalInfo.student_graduation" :disabled="!editInfo">
                  <el-option v-for="graduation in graduations" :label="graduation" :value="graduation"
                             :key="graduation"></el-option>
                </el-select>

              </div>
            </div>
          </section>
          <section style="margin-top: 60px">
            <p class="personal-title">Contact Information</p>
            <div class="flex-row" style="margin-top: 40px">
              <p style="flex: 1">
                <label class="personal-content-title">Mobile Number</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_mobile" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <p style="flex: 1">
                <label class="personal-content-title">Email</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_email" :disabled="!editInfo"></el-input>
              </p>
            </div>
          </section>
          <section style="margin-top: 60px">
            <p class="personal-title">Guardian's Contact Info</p>
            <div class="flex-row" style="margin-top: 40px">
              <p style="flex: 1">
                <label class="personal-content-title">Guardian's Mobile</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_guardian_first_mobile" :disabled="!editInfo"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <p style="flex: 1">
                <label class="personal-content-title">Guardian's Email</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_guardian_first_email" :disabled="!editInfo"></el-input>
              </p>
            </div>
            <!-- <div class="flex-row" style="margin-top: 20px">
              <p style="flex: 1">
                <label class="personal-content-title">第二监护人-手机</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_guardian_second_mobile"></el-input>
              </p>
              <div style="width: 120px;height: 1px"></div>
              <p style="flex: 1">
                <label class="personal-content-title">第二监护人-邮箱</label>
                <el-input placeholder="Please Input" style="margin-top: 10px;height: 40px"
                          v-model="personalInfo.student_guardian_second_email"></el-input>
              </p>


            </div> -->
            <div class="flex-row" style="margin-top: 20px">
              <p style="flex: 1">
                <label class="personal-content-title">Supplementary Information</label>
                <el-input placeholder="Please Input" type="textarea" :rows="5" style="margin-top: 10px;"
                          v-model="personalInfo.student_supplement" :disabled="!editInfo"></el-input>
              </p>
            </div>
          </section>
          <section style="margin-top: 60px">
            <div class="personal-submit" @click="saveData">
              {{ editInfo ? 'Save' : 'Edit' }}
            </div>
          </section>
          <div style="height: 100px;width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack";
import {getStudentInfoByID, getUserGameInfo, updateStudentInDatabase} from '../../api/sports'
import {getUserId} from "../../utils/store";
import {formatDate} from '../../utils/date'

export default {
  name: "PersonalInfo",
  components: {
    ViewBack
  },
  data() {
    return {

      selectedTab: 0,
      graduations: ['2018', '2019', '2020', '2021', '2022', '2023', '2024'],
      selectedGender: '男',
      title: 'Personal Profile',
      personalInfo: {},
      gameList: [],

      editInfo: false,

      game_count: 0,
      latest_register_time: '--'
    }
  },
  mounted() {
    this.fetchData()
    this.doGetGameInfo()
  },
  methods: {

    saveData() {

      if (this.editInfo == false) {
        this.editInfo = true
        return
      }

      let params = {
        _id: this.personalInfo._id.$id,
        lastName: this.personalInfo.student_lastName,
        givenName: this.personalInfo.student_givenName,
        gender: this.selectedGender,
        lastName_pinyin: this.personalInfo.student_lastName_pinyin,
        givenName_pinyin: this.personalInfo.student_givenName_pinyin,
        graduation: this.personalInfo.student_graduation,
        identity_id: '',
        birth_date: '',

        mobile: this.personalInfo.student_mobile,
        email: this.personalInfo.student_email,
        qqnumber: '',
        wechat: '',

        guardian_first_mobile: this.personalInfo.student_guardian_first_mobile,
        guardian_first_email: this.personalInfo.student_guardian_first_email,
        guardian_second_mobile: '',
        guardian_second_email: '',

        supplement: this.personalInfo.student_supplement,

        account_id: this.personalInfo.account_id
      }
      updateStudentInDatabase(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.editInfo = false
          this.$message.success('更新成功')
          this.fetchData()
        } else {
          this.$message.error(result.msg)
        }
      })
    },

    fetchData() {
      getStudentInfoByID(getUserId()).then((res) => {
        this.personalInfo = res.data.data
        this.selectedGender = this.personalInfo.student_gender
      })
    },
    doGetGameInfo() {
      getUserGameInfo(getUserId()).then((res) => {
        let result = res.data.data
        if (result.latest_time) {
          let temp_time = result.latest_time
          if (temp_time != '') {
            this.latest_register_time = formatDate(new Date(temp_time * 1000), 'yyyy')
          }
        }
        this.game_count = result.game_count
        this.gameList = result.game_list

      })
    },
    selectGender(gender) {
      if (!this.editInfo) {
        return
      }
      this.selectedGender = gender
    },
    getGenderIcon(gender) {
      if (this.selectedGender == gender) {
        return require('../../assets/checkbox-selected.png')
      }
      return require('../../assets/checkbox-unselected.png')
    }
  }
}
</script>


<style scoped lang="less">

::-webkit-scrollbar {
  display: none;
}


.personal-tab {
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  line-height: 40px;
  width: 200px;
  height: 40px;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #FE7702;
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FE7702;
}

.personal-title {
  color: #FD7702;
  font-size: 24px;
}

.personal-content-title {
  color: #666666;
  font-size: 14px;
}

.personal-submit {
  cursor: pointer;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 18px;
  font-weight: normal;
  color: #FFFFFF;
}

.student-info {
  background-color: #FFF3E8;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

</style>
