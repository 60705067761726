import request from "../utils/request";

export function loginByStudent(student_email, student_pwd, project_code) {
    return request.post('./index.php', {
        function: "loginByStudent",
        student_email,
        student_pwd,
        project_code
    })
}

export function loginByContact(contact_email, contact_pwd, project_code) {
    return request.post('./index.php', {
        function: "loginByContact",
        contact_email,
        contact_pwd,
        project_code
    })
}


export function getProjects() {
    return request.post('./index.php', {
        function: "getProjects"
    })
}

export function getGames(user_id) {
    return request.post('./index.php', {
        function: "getGames",
        user_id
    })
}

export function getGame(game_id) {
    return request.post('./index.php', {
        function: "getGame",
        game_id
    })
}

// export function getGameInfo(){
//
// }


export function getFIGames(meeting_id) {
    return request.post('./index.php', {
        function: "getFIGames",
        meeting_id
    })
}


export function getAccountStudentApps(account_id, project_code, meeting_id) {
    return request.post('./index.php', {
        function: "getAccountStudentApps",
        account_id, project_code, meeting_id
    })
}

export function initMeetingApplication(account_id, project_code, meeting_id, student_id, status) {
    return request.post('./index.php', {
        function: "initMeetingApplication",
        account_id, project_code, meeting_id, student_id, status
    })
}

export function getPeriodAssets(user_id, game_id, period) {
    return request.post('./index.php', {
        function: "getPeriodAssets",
        user_id,
        game_id,
        period
    })
}

export function getPeriodInstrumentDeals(user_id, game_id, period) {
    return request.post('./index.php', {
        function: "getPeriodInstrumentDeals",
        user_id,
        game_id,
        period
    })
}

export function getPeriodInstrumentList(game_id, period) {
    return request.post('./index.php', {
        function: "getPeriodInstrumentList",
        game_id,
        period
    })
}

export function updatePeriodInstrumentDeals(user_id, game_id, period, deal_array) {
    return request.post('./index.php', {
        function: "updatePeriodInstrumentDeals",
        user_id,
        game_id,
        period,
        deal_array
    })
}

export function getInstrumentLatestPeriods(game_id, instrument_id, latest_period) {
    return request.post('./index.php', {
        function: "getInstrumentLatestPeriods",
        game_id,
        instrument_id,
        latest_period
    })
}

export function getApplicationInfoByID(student_id, project_code) {
    return request.post('./index.php', {
        function: "getApplicationInfoByID",
        student_id,
        project_code
    })
}

export function changePassword(id, initial_password, new_password, new_password_again) {
    return request.post('./index.php', {
        function: "changePassword",
        id, initial_password, new_password, new_password_again
    })
}

export function getPaypal(application_id) {
    return request.post('./index.php', {
        function: 'getPaypal',
        application_id
    })
}

export function paypalSucceedBack(application_id, data) {
    return request.post('./index.php', {
        function: 'paypalSucceedBack',
        application_id,
        data
    })
}

export function policyCheckV1(app_id, policy, checkbox) {
    return request.post('./index.php', {
        function: 'policyCheckV1',
        app_id,
        policy,
        checkbox
    })
}

export function getStudentInfoByID(id) {
    return request.post('./index.php', {
        function: 'getStudentInfoByID',
        id
    })
}

export function updateStudentInDatabase(params) {
    return request.post('./index.php', {
        function: 'updateStudentInDatabase',
        _id: params._id,

        lastName: params.lastName,
        givenName: params.givenName,
        gender: params.gender,
        lastName_pinyin: params.lastName_pinyin,
        givenName_pinyin: params.givenName_pinyin,
        graduation: params.graduation,
        identity_id: params.identity_id,
        birth_date: params.birth_date,


        mobile: params.mobile,
        email: params.email,
        qqnumber: params.qqnumber,
        wechat: params.wechat,


        guardian_first_mobile: params.guardian_first_mobile,
        guardian_first_email: params.guardian_first_email,
        guardian_second_mobile: params.guardian_second_mobile,
        guardian_second_email: params.guardian_second_email,
        supplement: params.supplement,


        account_id: params.account_id,
    })
}

export function getFIMeetings() {
    return request.post('./index.php', {
        function: 'getFIMeetings'
    })
}

export function getMyMessages(id, project) {
    return request.post('./index.php', {
        function: 'getMyMessages',
        id,
        project
    })
}

export function sendMessage(userId, project, content, type, accessory) {
    return request.post('./index.php', {
        function: 'sendMessage',
        userId,
        project,
        content,
        type,
        accessory
    })
}

export function getProject(project_code) {
    return request.post('./index.php', {
        function: 'getProject',
        project_code,
    })
}

export function getAccountApplication(account_id) {
    return request.post('./index.php', {
        function: 'getAccountApplication',
        account_id,
    })
}

export function getAccount(account_id) {
    return request.post('./index.php', {
        function: 'getAccount',
        account_id,
    })
}

export function addNewApplication(params) {
    return request.post('./index.php', {
        function: 'addNewApplication',
        project_code: params.project_code,
        account_id: params.account_id,
        student_lastName: params.student_lastName,
        student_givenName: params.student_givenName,
        student_lastName_pinyin: params.student_lastName_pinyin,
        student_givenName_pinyin: params.student_givenName_pinyin,
        student_gender: params.student_gender,
        student_graduation: params.student_graduation,
        student_mobile: params.student_mobile,
        student_email: params.student_email,
        student_guardian_first_mobile: params.student_guardian_first_mobile,
        student_guardian_first_email: params.student_guardian_first_email,
        application_event: params.application_event,
    })
}

export function saveAppForm(form) {
    return request.post('./index.php', {
        function: 'saveAppForm',
        form
    })
}

export function getUserGameInfo(user_id) {
    return request.post('./index.php', {
        function: 'getUserGameInfo',
        user_id
    })
}

export function initGameApplication(params) {
    return request.post('./index.php', {
        function: 'initGameApplication',
        account_id: params.account_id,
        student_id: params.student_id,
        project_code: params.project_code,
        meeting_id: params.meeting_id,
        game_id: params.game_id,
        application_team_code: params.application_team_code,
    })
}

export function updateGameApplication(id, application_team_code) {
    return request.post('./index.php', {
        function: 'updateGameApplication', id, application_team_code
    })
}

export function deleteGameApplication(app_id) {
    return request.post('./index.php', {
        function: 'deleteGameApplication', app_id
    })
}
