<template>
  <div id="app">
    <header class="header" v-if="isShowHeader">
      <div class="view-root">
        <div class="header-container" :style="{height:`${header_height}vh`}">
          <img src="./assets/header_logo.png" style="width: 265px;">
          <div style="display: flex;flex-direction: row;height: 100%;justify-content: center;margin: 0 3.12vw">
            <div v-for="(item,index) in tabs" class="header-item" @click="handleTabChanged(item,index)">
              <p :class="checkIsContainsPath(item)?'header-item-title header-item-title-selected':'header-item-title '">
                {{ item.title }}</p>
            </div>
          </div>
          <div class="logout" @click="logout">Logout</div>
        </div>
      </div>
    </header>
    <el-container style="margin: 0px;padding: 0px">
      <el-main style="margin: 0px;padding: 0px">
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {clearUser, getUserName} from "@/utils/store";
import './assets/common/common.css'

export default {
  name: 'App',
  data() {
    return {
      header_height: 12,
      userName: '',
      tabs: [
        {
          title: 'News',
          selected: true,
          path: '/home/news',
          short_path: 'home/news'
        },
        {
          title: 'Panel',
          selected: false,
          path: '/home/panel',
          short_path: 'home/panel'
        },
        {
          title: 'Trainings',
          selected: false,
          path: '/home/references',
          short_path: 'home/references'
        },
        {
          title: 'Games',
          selected: false,
          path: '/home/game',
          short_path: 'home/game'
        },
        {
          title: 'Messages',
          selected: false,
          path: '/home/messages',
          short_path: 'home/messages'
        },
      ]
    }
  },
  mounted() {
    this.userName = getUserName()
  },
  computed: {
    isShowHeader() {
      // return false
      if (this.$route.path.search('appform') >= 0) {
        return false
      }
      if (this.$route.path.search('appapply') >= 0) {
        return false
      }
      if (this.$route.path.search('appform') >= 0) {
        return false
      }
      return this.$route.path.search('login') <= 0;
    }
  },
  methods: {

    checkIsContainsPath(item) {
      return this.$route.path.search(item.short_path) >= 0;
    },

    handleTabChanged(tab, index) {
      // for (var i = 0; i < this.tabs.length; i++) {
      //   this.tabs[i].selected = false
      // }
      // this.tabs[index].selected = true
      this.$router.push(tab.path)
    },

    logout() {
      clearUser()
      location.reload()
    }
  }
}
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>
<style scoped lang="less">

.header-item {
  margin-right: 6.42vw;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.416vw;
}

.header-item:last-child {
  margin-right: 0px;
}

.header-item-title {
  color: white;
  font-size: 1.1vw;
  font-weight: 400;
  border-bottom-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-style: solid;
  padding: 0 0 8px 0;
  border-color: transparent;
}

.header-item-title-selected {
  color: white;
  border-color: white;
  //color: #FE7702;
  //border-color: #FE7702;
}

.header-container {
  background-color: #212121;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logout {
  cursor: pointer;
  box-sizing: border-box;
  padding: 6px 16px;
  text-align: center;
  font-weight: bold;
  border-radius: 20px;
  font-size: 1vw;
  font-weight: bold;
  color: #FF7800;
  border: 2px solid #FE7702;
}

</style>
