<template>
  <div class="view-root">
    <div class="view-content home-content">
      <div style="width: 100%;height: 0vh"></div>
      <div style="width: 100%;max-width: 1360px;background-color: white;margin: 0 auto;height: 88vh;">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'

export default {
  name: "Home",
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="less">
.home-content {
  width: 100%;
  height: 88vh;
  background-image: url("../assets/root_bg.png");
  background-size: cover;
  background-position: 100%;
  background-color: transparent;
}

</style>