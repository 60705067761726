<template>
  <div>
    <div class="container">
      <div class="flex-column-left" style="flex: 1">
        <p class="sub_title">Current Available Cash</p>
        <p class="price">¥{{ formatPrice(current_available_cash) }}</p>
      </div>
      <div class="flex-column-left" style="flex: 1;">
        <p class="sub_title">Expected Closing Cash</p>
        <p class="price">¥{{ formatPrice(expected_closing_cash) }}</p>
      </div>
      <div class="flex-column-left" style="flex: 1">
        <p class="sub_title">Total Assets</p>
        <p class="price">¥{{ formatPrice(total_assets) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'
import {addCommaToThousands} from "../utils/NumberUtils";

export default {
  name: "CurrentInvestmentDecision",
  props: {
    total_assets: {
      type: String,
      default: ''
    },
    current_available_cash: {
      type: String,
      default: ''
    },
    expected_closing_cash: {
      type: String,
      default: ''
    }
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    }
  }
}
</script>

<style scoped>

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 20px 20px 60px;
  box-sizing: border-box;
  background-image: url("../assets/current_devision_bg.png");
}

.sub_title {
  color: white;
  font-size: 24px;
}

.price {
  margin-top: 5px;
  color: white;
  font-size: 36px;
}

</style>