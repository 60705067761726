<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>
      <div style="margin-top: 30px;width: 100%;padding-left: 180px;padding-right: 180px;box-sizing: border-box">
        <div style="width: 100%;background-color: #FFF8F0;padding: 48px 60px;">
          <div class="flex-row">
            <p class="pay-confirm-title">项目名称</p>
            <p class="pay-confirm-value">{{ applicationInfo.project_info.project_name }}</p>
          </div>
          <div class="flex-row" style="margin-top: 30px">
            <p class="pay-confirm-title">付款金额</p>
            <p class="pay-confirm-value">
              {{
                applicationInfo.application_payment_amount + ' (' + applicationInfo.application_payment_currency + ')'
              }}</p>
          </div>
        </div>
        <p class="pay-confirm-title" style="margin-top: 40px">条款</p>
        <div style="width: 100%;background-color: #FFF8F0;padding: 48px 60px;margin-top: 25px">
          <p class="pay-confirm-title" style="font-size: 22px;font-weight: normal">
            {{ applicationInfo.project_info.project_refund_policy }}
          </p>
        </div>

        <div class="flex-row-center" style="margin-top: 20px">
          <p style="color: #333333;font-size: 20px">
            <img :src="switchCheckbox()" @click="selectedPolicy =!selectedPolicy"
                 style="width: 14px;height: auto;cursor: pointer">
            我已阅读并同意以上条款</p>
        </div>

        <div class="flex-row-center" style="margin-top: 50px">
          <p class="btn-cancel" @click="doCancelPay">取消</p>
          <p class="btn-confirm" style="margin-left: 40px" @click="doPay">确认</p>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showPayDialog"
               width="813px"
               height="831px"
               center
               custom-class="dialog_class"
               close-on-click-modal
               close-on-press-escape
               title="Pay List">

      <!--      &lt;!&ndash;沙盒环境&ndash;&gt;-->
      <!--      <PayPal env="sandbox" :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials"-->
      <!--              :button-style="buttonStyle" @payment-authorized="paymentAuthorized"-->
      <!--              @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">-->
      <!--      </PayPal>-->


      <PayPal :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials"
              :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
              @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
      </PayPal>

    </el-dialog>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack";
import {getProjectCode, getUserId} from "../../utils/store";
import PayPal from "vue-paypal-checkout";
import {getApplicationInfoByID, getPaypal, paypalSucceedBack, policyCheckV1} from "../../api/sports";
//// paypal 测试账号  sb-vvzgb17842176@personal.example.com  密码 7F=Ub81o
export default {
  name: "PayConfirm",
  components: {
    ViewBack,
    PayPal
  },
  data() {
    return {
      selectedPolicy: false,
      title: '付款确认',
      applicationInfo: {
        project_info: {},
      },

      buttonStyle: {
        label: 'pay',
        size: 'small',
        shape: 'rect',
        color: 'blue'
      },
      credentials: {
        sandbox: '',
        production: ''
      },
      showPayDialog: false,
      orderData: {},
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    doCancelPay() {
      this.$router.go(-1)
    },

    fetchData() {
      getApplicationInfoByID(getUserId(), getProjectCode()).then((res) => {
        let result = res.data.data
        this.applicationInfo = result.application_info
      })
    },

    switchCheckbox() {
      if (this.selectedPolicy) {
        return require('../../assets/checkbox-selected.png')
      }
      return require('../../assets/checkbox-unselected.png')
    },
    doPay() {
      if (!this.selectedPolicy) {
        this.$message.error('Please check the payment policy')
        return
      }
      getPaypal(this.applicationInfo._id.$id).then((res) => {
        var result = res.data.data
        result.amount = String(result.amount)
        this.orderData = result
        this.credentials.sandbox = result.client_id
        this.credentials.production = result.live_client_id
        this.showPayDialog = true
      })
    },
    paymentAuthorized(data) {
      // 授权完成的回调，可以拿到订单id
    },

    paymentCompleted(data) {
      // 用户支付完成的回调，可以拿到订单id
      this.$message.success('Payment Success')
      this.showPayDialog = false
      this.paypalSucceedBack(data)
    },

    paymentCancelled(data) {
      this.$message.success('Payment Cancelled')
      this.showPayDialog = false
    },

    paypalSucceedBack(data) {
      paypalSucceedBack(this.applicationInfo._id.$id, data).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: 'Payment Success!',
            type: 'success'
          })
          this.doPolicyCheck()
        }
      })
    },
    doPolicyCheck() {
      policyCheckV1(this.applicationInfo._id.$id, this.applicationInfo.project_info.project_refund_policy, '是').then((res) => {
        this.$router.go(-1)
      })
    },
  }
}
</script>

<style scoped lang="less">

.btn-cancel {
  cursor: pointer;
  text-align: center;
  width: 200px;
  height: 50px;
  line-height: 50px;
  background: #FFBC83;
  border-radius: 25px 25px 25px 25px;
  font-size: 24px;
  color: white;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.btn-confirm {
  cursor: pointer;
  text-align: center;
  width: 200px;
  height: 50px;
  line-height: 50px;
  background: #FE7702;
  border-radius: 25px 25px 25px 25px;
  font-size: 24px;
  color: white;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}


.pay-confirm-title {
  flex: 1;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
}

.pay-confirm-value {
  color: #583518;
  font-size: 24px;
}
</style>
