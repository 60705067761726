<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="data-content-show">
      <ViewBack :title="title"/>

      <el-table :data="dataList" style="width: 100%;margin-top: 30px">
        <el-table-column prop="student_name" label="学生姓名"></el-table-column>
        <el-table-column prop="student_email" label="学生邮箱"></el-table-column>
        <el-table-column label="是否报名该Meeting">
          <template slot-scope="scope">
            <el-select
                v-model="scope.row.is_join_meeting"
                @change="changeStatus(scope.row.student_id,scope.row.is_join_meeting)"
            >
              <el-option v-for="graduation in options" :label="graduation.name" :value="graduation.value"
                         :key="graduation.value"></el-option>
            </el-select>

          </template>


        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <div>
              <h3>报名GameList</h3>
              <el-table :data="props.row.game_app_list">
                <el-table-column prop="project_code" label="ProjectCode"></el-table-column>
                <el-table-column prop="application_team_code" label="TeamCode"></el-table-column>
                <el-table-column prop="" label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="editStudentGame(props.row,scope.row)">编辑</el-button>
                    <el-button type="danger" size="small" @click="deleteStudentApp(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button @click="addGame(props.row)" style="margin-top: 10px">新增</el-button>
            </div>
          </template>
        </el-table-column>

      </el-table>

      <el-dialog :visible.sync="showStudentGame" title="选择Game">
        <div>
          <el-form v-model="selectAppInfo" label-position="top">
            <el-form-item label="选择Game">
              <el-select v-model="selectAppInfo.game_id" style="width: 500px"
                         :disabled="selectAppInfo.student_app_id!=''">
                <el-option v-for="item in gameList" :key="item._id.$id" :label="item.name"
                           :value="item._id.$id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择分组">
              <el-select v-model="selectAppInfo.application_team_code" style="width: 500px">
                <el-option v-for="(item) in team_codes" :key="item" :label="item"
                           :value="item"/>
              </el-select>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button @click="showStudentGame = false">取 消</el-button>
            <el-button type="primary" @click="doSelectGame">确 定</el-button>
          </span>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import '../../assets/common/common.css'
import ViewBack from "../../components/ViewBack"
import {
  deleteGameApplication,
  getAccountStudentApps,
  getFIGames,
  initGameApplication,
  initMeetingApplication,
  updateGameApplication
} from '../../api/sports'
import {getProjectCode, getUserAccountId} from "../../utils/store";

export default {
  name: "Passwords",
  components: {
    ViewBack
  },
  data() {
    return {
      team_codes: [],
      title: '',
      dataList: [],
      allGameList: [],
      gameList: [],
      value1: [],
      showStudentGame: false,
      meeting_id: this.$route.query.meeting_id,
      options: [
        {'name': '否', 'value': false},
        {'name': '是', 'value': true}
      ],
      selectAppInfo: {
        game_id: '',
        student_id: '',
        application_team_code: '',
        student_app_id: ''
      }
    }
  },
  mounted() {
    for (var i = 1; i <= 50; i++) {
      this.team_codes.push(i)
    }
    this.fetchData()
    this.getGamesssss()
  },
  methods: {
    fetchData() {
      getAccountStudentApps(getUserAccountId(), getProjectCode(), this.meeting_id).then((res) => {
        let result = res.data.data
        this.dataList = result.map((item) => {
          item['id'] = item._id.$id
          return item
        })
        this.dataList = res.data.data

      })
    },
    deleteStudentApp(row) {
      let app_id = row._id.$id
      let that = this
      this.$alert('确定要删除此游戏吗？', '提示', {
        callback: action => {
          if (action == 'confirm') {
            deleteGameApplication(app_id).then((res) => {
              that.$message.success('删除成功')
              that.fetchData()
            })
          }
        }
      })
    },
    getGamesssss() {
      getFIGames(this.meeting_id).then((res) => {
        this.allGameList = res.data
      })
    },
    addGame(row) {
      this.filterGameList(row.game_app_list)
      if (this.gameList.length <= 0) {
        this.$message.warning('暂无可选择的游戏!')
        return
      }
      this.selectAppInfo.student_id = row.student_id
      this.selectAppInfo.game_id = ''
      this.selectAppInfo.application_team_code = ''
      this.selectAppInfo.student_app_id = ''
      this.showStudentGame = true;
    },
    changeStatus(student_id, status) {
      var statusStr = 'cancel'
      if (status == true) {
        statusStr = 'activity'
      }
      initMeetingApplication(getUserAccountId(), getProjectCode(), this.meeting_id, student_id, statusStr).then((res) => {
        this.fetchData()
      })
    },
    editStudentGame(student_row, game_row) {
      this.gameList = this.allGameList
      this.selectAppInfo.student_id = student_row.student_id
      this.selectAppInfo.game_id = game_row.game_id
      this.selectAppInfo.application_team_code = game_row.application_team_code
      this.selectAppInfo.student_app_id = game_row._id.$id
      this.showStudentGame = true;
    },
    doSelectGame() {
      if (this.selectAppInfo.student_app_id != '') {
        updateGameApplication(this.selectAppInfo.student_app_id, this.selectAppInfo.application_team_code).then((res) => {
          this.showStudentGame = false
          this.$message.success('更新成功')
          this.fetchData()
        })
        return
      }
      let that = this
      this.$alert('确定要选择此游戏吗？', '提示', {
        callback: action => {
          if (action == 'confirm') {
            let params = {
              account_id: getUserAccountId(),
              student_id: that.selectAppInfo.student_id,
              project_code: getProjectCode(),
              meeting_id: that.meeting_id,
              game_id: that.selectAppInfo.game_id,
              application_team_code: that.selectAppInfo.application_team_code
            }
            initGameApplication(params).then((res) => {
              that.showStudentGame = false
              that.$message.success('添加成功')
              that.fetchData()
            })
          } else {
            that.showStudentGame = false
          }
        }
      })
    },
    filterGameList(app_list) {
      this.gameList = []
      if (app_list == null || app_list.length <= 0) {
        this.gameList = this.allGameList
        return
      }
      let that = this
      this.allGameList.forEach(function (app) {
        let game_id = app._id.$id
        var enabledAdd = true
        app_list.forEach(function (child) {
          let add_game_id = child.game_id
          if (game_id == add_game_id) {
            enabledAdd = false
          }
        })
        if (enabledAdd) {
          that.gameList.push(app)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.pwd-name {
  color: #FE7702;
  font-size: 24px;
  font-weight: bold;
}

.pwd-submit {
  cursor: pointer;
  margin-top: 50px;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 18px;
  font-weight: normal;
  color: #FFFFFF;
}

</style>
