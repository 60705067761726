<template>
  <div>
    <div style="width: 100%;padding: 40px;margin: 0;box-sizing: border-box">
      <div class="flex-row">
        <div style="flex: 1;text-align: left">
          <p style="color: #583518;font-size: 1.44vw;font-weight: bold;">Investment Game</p>
        </div>
        <img src="../assets/icon_close.png" style="width: 1.04vw;height: auto;object-fit: contain;cursor: pointer"
             @click="closeDialog()">
      </div>
      <div class="flex-row-center" style="margin-top: 30px">
        <div class="circle" style="background: #E2E2E2"></div>
        <p class="select-title">Instrument List</p>
        <div style="width: 120px;height: 1px;background-color: #707070;margin: 0 20px"></div>
        <div class="circle"></div>
        <p class="select-title" style="color: #666666">Investment Instrument</p>
      </div>
      <div style="width: 100%;text-align: left;margin-top: 2.6vw">
        <div class="title">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw">
            <div>Instrument</div>
            <div style="margin-top: 1vw;color: #FE7702;font-size: 1.56vw">{{ investment_name }}
            </div>
          </div>

        </div>
        <div class="title" style="margin-top: 2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>Descriptions</div>
            <div class="descriptions">{{ investment_describe }}
            </div>
          </div>

        </div>
        <div class="title" style="margin-top: 2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>Current Price</div>
            <div class="current-price">
              <p style="color: #FE7702;font-size: 1.872vw;margin-right: 2.08vw">¥{{ formatPrice(investment_price) }}</p>
              <p class="change-percent" :style="{color:changePercentAdd()?'#10BF3D':'#FE390B'}">
                {{ investment_change_percent == 'NA' ? '' : investment_change_percent }}</p>
              <div class="reasons-for-change"
                   style=";align-items:center;display: flex;flex-direction: row;cursor: pointer" @click="showChange">
                <img src="../assets/icon_question.png" style="width:  1.248vw">
                <p style="margin-left: 10px">Check reasons for change</p>
              </div>
            </div>
          </div>
        </div>
        <div class="title" style="margin-top: 2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>Operation</div>
            <div class="operation">
              <div class="bg-buy" @click="changeOperation('buy')">
                <img src="../assets/select_buy_sell.png" v-if="buyOrSell=='buy'"
                     style="position: absolute;right: 0;top: 0">
                <p style="color: #F40000;font-size: 30px;margin-left: 40px">Buy</p>
              </div>
              <div class="bg-sell" @click="changeOperation('sell')">
                <img src="../assets/select_buy_sell.png" v-if="buyOrSell=='sell'"
                     style="position: absolute;right: 0;top: 0">
                <p style="color: #00BA39;font-size: 30px;margin-left: 40px">Sell</p>
              </div>
            </div>
          </div>

        </div>

        <div class="title" style="margin-top:2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>{{ buyOrSell == 'buy' ? 'Buy' : 'Sell' }} Unit(s)</div>
            <el-input-number :min="0" class="el-input-number" style="margin-top: 1vw" v-model="number_units"
                             :placeholder="buyOrSell =='buy'?'Input a number':('Maximum sellable ' + canSellNum)"
                             @change="handleNumberChanged"></el-input-number>
          </div>
        </div>

        <div class="title" style="margin-top: 2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>{{ buyOrSell == 'sell' ? ('Income：') : ('Expense：') }}</div>
            <p style="margin-top: 1vw;color: #666666;font-size: 1.872vw">
              {{ buyOrSell == 'sell' ? ('+ $' + formatPrice(expenseStr)) : ('- $' + formatPrice(expenseStr)) }}</p>
          </div>
        </div>
        <div class="title" style="margin-top: 2.6vw">
          <div class="point"/>
          <div class="flex-column-left" style="margin-top: -0.52vw;width: 100%;">
            <div>Expected closing cash</div>
            <p style="margin-top: 1vw;color: #666666;font-size: 1.872vw">¥{{ formatPrice(shengyuJE) }}</p>
          </div>
        </div>
        <div class="add" @click="saveInvestment()">Add</div>
        <div class="add" v-if="selectType =='edit'" @click="deleteDecision()" style="margin-top:1.04vw">Delete</div>
      </div>
    </div>
  </div>
</template>
<script>
import '../assets/common/common.css'
import {addCommaToThousands} from "../utils/NumberUtils";

export default {
  name: "AddInvestment",
  data() {
    return {
      instrument_id: '',
      investment_name: '',
      investment_describe: '',
      investment_price: 0,
      investment_change_percent: '',
      investment_change_reason: '',

      buyOrSell: 'buy',
      number_units: 0,
      canSellInfo: {},
      canSellNum: 0,
      shengyuJE: 0,
      expenseStr: '0.00',
      allMoney: 0,

      selectType: 'add'
    }
  },
  mounted() {
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    },
    changeOperation(operation) {
      if (operation == 'buy') {
        this.buyOrSell = operation
        this.handleNumberChanged(this.number_units)
      } else {
        var canSellNumInt = this.canSellInfo[this.instrument_id]
        if (canSellNumInt == undefined || canSellNumInt <= 0) {
          this.$message.error('No available units now')
          return
        }
        this.canSellNum = canSellNumInt
        this.buyOrSell = operation
        this.handleNumberChanged(this.number_units)
      }
    },

    handleNumberChanged(e) {

      var numStr = this.number_units

      if (numStr == '') {
        numStr = 0
      }

      var isnum = /^\d+$/.test(numStr);

      if (!isnum) {
        this.$message.error('Input a number')
        return
      }
      var shouldBuy = this.investment_price * numStr


      shouldBuy = parseFloat(shouldBuy).toFixed(2);
      var expense_str = ''
      var cha = this.allMoney - shouldBuy
      expense_str = shouldBuy
      if (this.buyOrSell == 'sell') {
        cha = this.FloatAdd(this.allMoney, shouldBuy)
        expense_str = shouldBuy
      }
      cha = parseFloat(cha).toFixed(2);

      this.shengyuJE = cha
      this.expenseStr = expense_str
    },

    fetchData(instrument, periodAssets, allMoney, type) {
      this.reset()
      this.selectType = type
      if (type == 'edit') {
        this.instrument_id = instrument.instrument_id
        this.investment_name = instrument.name
        this.investment_describe = instrument.describe
        this.investment_price = instrument.price
        this.investment_change_percent = instrument.change_percent
        this.investment_change_reason = instrument.change_reason
        this.buyOrSell = instrument.type
        this.number_units = instrument.quantity

        this.handleNumberChanged()

      } else {
        this.instrument_id = instrument.instrument_id
        this.investment_name = instrument.instrument_info.name
        this.investment_describe = instrument.instrument_info.describe
        this.investment_price = instrument.price / 100
        this.investment_change_percent = instrument.change_percent
        this.investment_change_reason = instrument.change_reason
      }
      this.canSellInfo = periodAssets.assets_array
      this.allMoney = allMoney
      this.shengyuJE = allMoney
    },

    changePercentAdd() {
      if (this.investment_change_percent.indexOf("+") != -1) {
        return true
      }
      return false
    },

    showChange() {
      this.$alert(this.investment_change_reason, '提示', {
        confirmButtonText: '确定',
        callback: action => {
        }
      });
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    saveInvestment() {
      if (this.number_units <= 0) {
        this.$message.error('Input a number')
        return
      }
      var canBuyNumInt = parseInt(this.allMoney / (this.investment_price))

      if (this.buyOrSell == 'buy') {
        if (parseInt(this.number_units) > parseInt(canBuyNumInt)) {
          this.$message.error('Exceeding the buyable quantity')
          return
        }
      } else {
        if (parseInt(this.number_units) > parseInt(this.canSellNum)) {
          this.$message.error('Exceeding the sellable quantity')
          return;
        }
      }

      var investment_decision = {}
      var money = parseFloat(this.number_units * this.investment_price).toFixed(2);

      investment_decision = {
        'name': this.investment_name,
        'quantity': parseInt(this.number_units),
        'price': this.investment_price,
        'money': money,
        'type': this.buyOrSell,
        'instrument_id': this.instrument_id,
        'change_percent': this.investment_change_percent,
        'describe': this.investment_describe,
        'change_reason': this.investment_change_reason
      }
      this.$emit('saveData', {data: investment_decision, selectType: this.selectType})
    },
    deleteDecision() {
      let investment_decision = {
        'instrument_id': this.instrument_id,
      }
      this.$emit('saveData', {data: investment_decision, selectType: 'delete'})
    },
    FloatAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    reset() {
      this.buyOrSell = 'buy'
      this.number_units = 0
      this.canSellInfo = {}
      this.canSellNum = 0
      this.shengyuJE = 0
      this.expenseStr = '0.00'
      this.allMoney = 0
    }
  }
}
</script>

<style>
/*/deep/ .el-input__inner {*/
/*  background-color: white;*/
/*  border-radius: 2px 2px 2px 2px;*/
/*  border: 1px solid #CCCCCC;*/
/*  outline: none;*/
/*  color: #666666;*/
/*  font-size: 20px;*/
/*}*/

</style>

<style scoped lang="less">

.select-title {
  color: #FE7702;
  font-size: 1.04vw;
}

.change-percent {
  font-size: 1.56vw;
  flex: 1
}

.el-input-number {
  box-sizing: border-box;
  background-color: white;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #CCCCCC;
  outline: none;
  color: #666666;
  font-size: 20px;
}

.bg-sell {
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  width: 240px;
  height: 80px;
  line-height: 80px;
  background-image: url("../assets/bg_sell.png");
  background-repeat: no-repeat;
}

.bg-buy {
  position: relative;
  cursor: pointer;
  width: 240px;
  height: 80px;
  line-height: 80px;
  background-image: url("../assets/bg_buy.png");
  background-repeat: no-repeat;
}


.operation {
  margin-top: 1vw;
  display: flex;
  flex-direction: row;
}

.current-price {
  margin-top: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.04vw 2.08vw;
  box-sizing: border-box;
  background-color: #FFF8F0;
  border-radius: 2px;
}

.descriptions {
  margin-top: 1vw;
  width: 100%;
  font-size: 0.936vw;
  font-weight: normal;
  color: #666666;
  line-height: 1.872vw;
}

.title {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #583518;
  font-size: 1.248vw;
  font-weight: bold;
}

.point {
  margin-right: 10px;
  background-color: #BEA48F;
  width: 0.52vw;
  height: 0.52vw;
  border-radius: 50%;
}

.reasons-for-change {
  box-sizing: border-box;
  background-color: #FFE6D9;
  border-radius: 2px;
  padding: 0.416vw 0.52vw 0.416vw 1.04vw;
  color: #FE7702;
  font-size: 0.832vw;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FE7702;
  margin-right: 15px;
}


.add {
  cursor: pointer;
  margin-top: 2.6vw;
  text-align: center;
  line-height: 2.08vw;
  width: 8.32vw;
  height: 2.08vw;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 0.936vw;
  font-weight: normal;
  color: #FFFFFF;
}
</style>
