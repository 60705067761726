export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            const str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}

export  function formatMonthToEng(month) {
    var temp_month = ''
    switch (month) {
        case 1:
            temp_month = 'Jan'
            break;
        case 2:
            temp_month = 'Feb'
            break
        case 3:
            temp_month = 'Mar'
            break
        case 4:
            temp_month = 'Apr'
            break
        case 5:
            temp_month = 'May'
            break
        case 6:
            temp_month = 'Jun'
            break
        case 7:
            temp_month = 'Jul'
            break
        case 8:
            temp_month = 'Aug'
            break
        case 9:
            temp_month = 'Sep'
            break
        case 10:
            temp_month = 'Oct'
            break
        case 11:
            temp_month = 'Nov'
            break
        default:
            temp_month = 'Dec'
            break
    }
    return temp_month
}
