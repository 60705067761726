<template>
  <div class="data-content" style="height: 100%;overflow-y: auto;">
    <div class="title">
      <p style="margin-left:100px">Account Management</p>
    </div>
    <div style="width: 100%;margin-top: 20px;padding-left: 100px;padding-right: 100px;box-sizing: border-box;">
      <div class="flex-row" style="justify-content: flex-start;flex-wrap: wrap" @click="goPersonal">
        <div class="panel-item flex-row" style="align-items: center">
          <img src="../../assets/icon_persional.png">
          <p style="margin-left: 10px">Personal Profile</p>
        </div>
        <div class="panel-item flex-row" style="align-items: center" @click="goPasswords">
          <img src="../../assets/icon_pwd.png">
          <p style="margin-left: 10px">Change Password</p>
        </div>
        <!--      <div class="panel-item flex-row" style="align-items: center">-->
        <!--        <img src="../../assets/icon_sign.png">-->
        <!--        <p style="margin-left: 10px">签到/打卡</p>-->
        <!--      </div>-->
      </div>

    </div>


    <div class="title">
      <p style="margin-left:100px">Player Registration</p>
    </div>
    <div style="width: 100%;margin-top: 20px;padding-left: 100px;padding-right: 100px;box-sizing: border-box;">
      <div class="flex-row" style="justify-content: flex-start;flex-wrap: wrap">
        <div class="panel-item flex-row" style="align-items: center" @click="goApplication">
          <img src="../../assets/icon_register.png">
          <p style="margin-left: 10px">Registration Info</p>
        </div>
        <!--      <div class="panel-item flex-row" style="align-items: center">-->
        <!--        <img src="../../assets/icon_achievement.png">-->
        <!--        <p style="margin-left: 10px">查看成绩</p>-->
        <!--      </div>-->
      </div>

    </div>

    <!--  <div class="title">-->
    <!--    <p style="margin-left:100px">金融投资与理财（Financial Literacy）</p>-->
    <!--  </div>-->
    <!--  <div style="width: 100%;margin-top: 20px;padding-left: 100px;padding-right: 100px;box-sizing: border-box;">-->
    <!--    <div class="flex-row" style="justify-content: flex-start;flex-wrap: wrap">-->
    <!--      <div class="panel-item flex-row" style="align-items: center">-->
    <!--        <img src="../../assets/icon_investment.png">-->
    <!--        <p style="margin-left: 10px">投资决策(挑战环节)</p>-->
    <!--      </div>-->
    <!--      <div class="panel-item flex-row" style="align-items: center">-->
    <!--        <img src="../../assets/icon_sign.png">-->
    <!--        <p style="margin-left: 10px">报名链接</p>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--  </div>-->


  </div>
</template>

<script>
import '../../assets/common/common.css'

export default {
  name: "Dashbord",
  data() {
    return {}
  },
  methods: {
    goPersonal(){
      this.$router.push('panel/personal')
    },
    goApplication() {
      this.$router.push('panel/application')
    },
    goPasswords() {
      this.$router.push('panel/passwords')
    }
  }
}
</script>

<style scoped lang="less">

.panel-item {
  margin-bottom: 1.248vw;
  min-width: 240px;
  margin-right: 1.248vw;
  cursor: pointer;
  padding: 26px 10px 22px 40px;
  box-sizing: border-box;
  width: 20vw;
  max-width: 385px;
  font-size: 18px;
  color: #583518;
  font-weight: bold;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 5px 16px 1px rgba(0, 0, 0, 0.08);
}


</style>
