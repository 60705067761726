<template>
  <div>
    <div style="width: 100%;padding: 2.08vw;margin: 0;box-sizing: border-box">
      <div class="flex-row">
        <div style="flex: 1;text-align: left">
          <p style="color: #583518;font-size: 1.44vw;font-weight: bold;">Investment Game</p>
        </div>
        <img src="../assets/icon_close.png" style="width: 1.04vw;height: auto;object-fit: contain;cursor: pointer"
             @click="closeDialog()">
      </div>
      <div class="flex-row-center" style="margin-top: 30px">
        <div class="circle"></div>
        <p class="select-title">Instrument List</p>
        <div style="width: 120px;height: 1px;background-color: #707070;margin: 0 20px"></div>
        <div class="circle" style="background: #E2E2E2"></div>
        <p class="select-title" style="color: #666666">Investment Instrument</p>
      </div>
      <div style="width: 100%;text-align: left">
        <el-select class="select_instrument" popper-append-to-body="false" v-model="select_instrument_type"
                   placeholder="Select an instrument type"
                   style="margin-top: 30px" @change="handleInstrumentTypeChanged">
          <el-option v-for="item in instrument_types" :value="item" :label="item"></el-option>
        </el-select>

        <div style="margin-top: 1.56vw">
          <div v-for="(instrument,index) in instruments"
               :class="instrument.selected?'instrument-item instrument-item-selected':'instrument-item'">
            <div @click="doSelectInstrument(index)" style="cursor: pointer">
              <img v-if="instrument.selected" src="../assets/checkbox_select.png">
              <img v-else src="../assets/checkbox_unselect.png">
            </div>
            <div style="margin-left: 40px;width: 100%">
              <div class="flex-row">
                <p class="item-name">{{ instrument.instrument_info.name }}</p>
                <p class="item-type">{{ instrument.instrument_info.type }}</p>
              </div>
              <p class="period-price">Period {{ period }} Price</p>
              <div class="flex-row" style="margin-top: 0.52vw;width: 100%;align-items: center;justify-content: center">
                <p class="instrument-price">¥{{ formatPrice(instrument.price / 100) }}</p>
                <p class="instrument-percent" :style="{color:instrument.change_percent_add?'#10BF3D':'#FE390B'}">
                  {{ instrument.change_percent == 'NA' ? '' : instrument.change_percent }}</p>
                <div class="reasons-for-change"
                     style=";align-items:center;display: flex;flex-direction: row;justify-content: center">
                  <img src="../assets/icon_question.png" style="margin-right: 12px;width: 1.248vw;height: auto">
                  <p style="cursor: pointer" @click="showChange(instrument.change_reason)"> Check reasons for change</p>
                </div>
              </div>

            </div>


          </div>


        </div>
        <div class="add-decision" @click="doSelectInvestment()">Add a decision</div>

      </div>
    </div>
  </div>
</template>

<script>
import {getPeriodInstrumentList} from '../api/sports'
import {addCommaToThousands} from "../utils/NumberUtils";

export default {
  name: "SelectInvestmentList",
  props: {},
  data() {
    return {
      select_instrument_type: 'All',
      instrument_types: ['All', 'Saving', 'Stock', 'Bond', 'Mutual Fund', 'Commodity', 'Futures', 'REITs', 'Equity', 'Digital', 'Collectibles'],
      instruments: [],
      originalDataList: []
    }
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    },
    fetchData(game_id, period, filterDecisions) {
      this.instruments = []
      this.originalDataList = []
      getPeriodInstrumentList(game_id, period).then((res) => {
        let result = res.data
        for (var i = 0; i < result.length; i++) {
          let temp = result[i]
          let temp_id = temp._id.$id
          var can_add = true
          for (var j = 0; j < filterDecisions.length; j++) {
            let selected_decision = filterDecisions[j]
            if (temp_id == selected_decision.instrument_id) {
              can_add = false
              break
            }
          }
          if (!can_add) {
            continue
          }
          if (temp.change_percent.indexOf("+") != -1) {
            temp.change_percent_add = true
          } else {
            temp.change_percent_add = false
          }
          temp['selected'] = false
          //过滤掉已经添加掉
          this.originalDataList.push(temp)
          this.instruments.push(temp)
        }
      })
    },
    handleInstrumentTypeChanged(e) {
      if (this.select_instrument_type == 'All') {
        this.instruments = this.originalDataList
      } else {
        this.instruments = []
        for (var i = 0; i < this.originalDataList.length; i++) {
          let temp = this.originalDataList[i]
          let type = temp.instrument_info.type
          if (type == this.select_instrument_type) {
            this.instruments.push(temp)
          }
        }
      }
    },

    showChange(info) {
      this.$alert(info, '提示', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
    },

    doSelectInstrument(index) {
      for (var i = 0; i < this.instruments.length; i++) {
        this.instruments[i].selected = false
      }
      this.instruments[index].selected = true
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    doSelectInvestment() {
      var instrument = undefined
      for (var i = 0; i < this.instruments.length; i++) {
        if (this.instruments[i].selected) {
          instrument = this.instruments[i]
        }
      }
      if (instrument == undefined) {
        this.$message.error("请选择")
        return
      }
      this.$emit('confirmSelectInvestment', instrument)
    }
  }
}
</script>

<style scoped lang="less">

.reasons-for-change {
  box-sizing: border-box;
  background-color: #FFEFE2;
  border-radius: 2px;
  padding: 0.416vw 0.52vw 0.416vw 1.04vw;
  color: #966237;
  font-size: 0.832vw;
}

.instrument-price {
  color: #FE7702;
  font-size: 1.872vw;
}

.instrument-percent {
  flex: 1;
  width: 100%;
  margin-left: 40px;
  font-size: 1.56vw;
}

.period-price {
  font-weight: bold;
  margin-top: 0.78vw;
  color: #583518;
  font-size: 0.832vw;
}

.item-name {
  width: 100%;
  color: #573518;
  font-size: 1.248vw;
  flex: 1;
}

.item-type {
  box-sizing: border-box;
  padding: 9px 15px;
  color: #FE7702;
  font-weight: normal;
  font-size: 0.832vw;
  border-radius: 2px 2px 2px 2px;
  background: #FFD9B9;
}

.instrument-item {
  margin-bottom: 1.04vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 1.248vw 1.248vw 1.248vw 2.08vw;
  border-radius: 2px;
  background: #FFFFFF;
  border: 4px solid transparent;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1600);
}

.instrument-item-selected {
  background: #FFF8F0;
  border: 4px solid #FE7702;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FE7702;
  margin-right: 15px;
}

.select-title {
  color: #FE7702;
  font-size: 1.04vw;
}


/deep/ .el-input__inner {
  background-color: white;
  height: 40px;
  outline: none;
  border-radius: 2px;
  color: #333333;
  font-size: 14px;;;
  border-width: 1px;
  border-color: #CCCCCC;
  border-style: solid;
}

/deep/ .el-select-dropdown__item {
  font-size: 14px;;;
  border-color: #CCCCCC;
}

.select_instrument {
  border-radius: 2px;
  color: #333333;
  font-size: 14px;
  width: 264px;
  height: 40px;
  background-color: white;
  border-width: 1px;
  border-color: #CCCCCC;
  border-style: solid;
  outline: none;
  padding: 0 15px 0 0px;
}

.add-decision {
  cursor: pointer;
  margin-top: 50px;
  text-align: center;
  line-height: 2.08vw;
  width: 8.32vw;
  height: 2.08vw;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 0.936vw;
  font-weight: normal;
  color: #FFFFFF;
}
</style>