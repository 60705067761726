<template>
  <div>
    <div class="container" @click="editDecision">
      <div class="flex-row">
        <p class="title">{{ investment_decision.name }}</p>
        <p class="buy_in">{{ investment_decision.type == 'buy' ? 'Buy in' : 'Sell out' }}</p>
      </div>
      <div class="flex-row" style="margin-top: 10px">

        <div class="flex-column-left" style="flex: 1">
          <p class="sub_title">Current Price</p>
          <p class="price">¥{{ formatPrice(investment_decision.price) }}</p>
        </div>
        <div class="flex-column-left" style="flex: 1">
          <p class="sub_title">Quantity</p>
          <p class="price">{{ investment_decision.quantity }} unit（s）</p>
        </div>
        <div class="flex-column-left" style="flex: 1">
          <p class="sub_title">Available Cash</p>
          <p class="price" :style="{color:investment_decision.type=='buy'?'#00BA39':'#F40000'}">
            {{
              investment_decision.type == 'buy' ? '-$' + formatPrice(investment_decision.money) : '+$' + formatPrice(investment_decision.money)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'
import {addCommaToThousands} from "../utils/NumberUtils";

export default {
  name: "InvestmentDecision",
  props: {
    investment_decision: {
      type: Object,
      default: ''
    }
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    },
    editDecision() {
      if (this.investment_decision.can_edit) {
        if (this.investment_decision.can_edit != 1) {
          this.$emit('editDecision', this.investment_decision)
        }
      } else {
        this.$emit('editDecision', this.investment_decision)
      }
    }
  }
}
</script>

<style scoped>

.container {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 20px 20px 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1600);
  border-radius: 2px 2px 2px 2px;
  margin-bottom: 20px;
}

.title {
  flex: 1;
  color: #573518;
  font-size: 24px;
  font-weight: bold;
}

.buy_in {
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  width: 80px;
  height: 40px;
  background: #FFEDDD;
  border-radius: 2px 2px 2px 2px;
  font-size: 16px;
  color: #FE7702;
}

.sub_title {
  color: #999999;
  font-size: 24px;
}

.price {
  margin-top: 5px;
  color: #FE7702;
  font-size: 36px;
}

</style>