<template>
  <div class="apply-container">
    <div class="apply-data-container">
      <div class="data-show">
        <p style="color:  #FE7702;font-size: 24px;font-weight: bold">Player Registration</p>
        <el-form v-model="form" style="margin-top: 30px" label-position="top">
          <el-form-item label="Season to Register">
            <el-input v-model="project.project_name" :disabled="true" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Enrolled School">
            <el-input v-model="account.account_name" :disabled="true" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Last Name in Native Language">
            <el-input v-model="form.student_lastName" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Given Name in Native Language">
            <el-input v-model="form.student_givenName" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Last Name in English">
            <el-input v-model="form.student_lastName_pinyin" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Given Name in English">
            <el-input v-model="form.student_givenName_pinyin" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Gender">
            <div class="flex-row item-width" style="align-items: center">
              <img src="../assets/checkbox-selected.png" class="checkbox" v-if="form.student_gender==='男'">
              <img src="../assets/checkbox-unselected.png" class="checkbox" v-else @click="form.student_gender='男'">
              <p style="margin-left: 10px">Male</p>
              <img src="../assets/checkbox-selected.png" class="checkbox" style="margin-left:37px"
                   v-if="form.student_gender==='女'">
              <img src="../assets/checkbox-unselected.png" class="checkbox" v-else style="margin-left:37px"
                   @click="form.student_gender='女'">
              <p style="margin-left: 10px">Female</p>
            </div>
          </el-form-item>

          <el-form-item label="Expected Year of High School Graduation">
            <el-select v-model="form.student_graduation" class="item-width">
              <el-option v-for="item in graduations" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Mobile Phone">
            <el-input v-model="form.student_mobile" type="mobile" class="item-width"></el-input>
          </el-form-item>

          <el-form-item label="Email (used as your account identifier)">
            <el-input v-model="form.student_email" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Guardian's Mobile">
            <el-input v-model="form.student_guardian_first_mobile" class="item-width"></el-input>
          </el-form-item>
          <el-form-item label="Guardian's Email">
            <el-input v-model="form.student_guardian_first_email" class="item-width"></el-input>
          </el-form-item>

          <el-form-item label="选择子项目" v-if="project.project_events_max">
            <el-select v-model="form.application_event" class="item-width">
              <el-option v-for="item in events" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <p style="margin-top: 20px;color: gray;font-size: 12px;line-height: 24px">
          Note: your email will be used as your account and player identifier. If you do not have an account, the system will automatically create one for you. If you have an existing account, this form will create registration entry for the specific season. This form is seasona and school specific. Please confirm your season and school to register under before submission. Password change will be mandated after new account is setup. After submission, this page will jumps to a registration fee payment page. You can also log in the system later to finish the payment.

          Contact：please email to info@fin-sports.com or send messages in the system
          Visit website：www.fin-sports.com

        </p>
        <p class="btn_submit" @click="submitData">Submit</p>
      </div>
    </div>
  </div>
</template>

<script>
import {addNewApplication, getAccount, getAccountApplication, getProject} from '../api/sports'
import '../assets/common/common.css'

export default {
  name: "appapply",
  data() {
    return {
      // project_code: '22-23FinSports',
      //account_id: '5be93f80a4640b531d8b462e',
      graduations: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      project_code: this.$route.query.project_code,
      account_id: this.$route.query.account_id,
      project: {},
      account: [],
      events: [],

      form: {
        student_lastName: '',
        student_givenName: '',
        student_lastName_pinyin: '',
        student_givenName_pinyin: '',
        student_gender: '男',
        student_graduation: '',
        student_mobile: '',
        student_email: '',
        student_guardian_first_mobile: '',
        student_guardian_first_email: '',
        application_event: ''
      }
    }
  },
  mounted() {

    if (this.project_code == undefined ||
        this.project_code == '' ||
        this.account_id == undefined ||
        this.account_id == ''
    ) {
      this.$message.error('Parameter Error!')
      this.$router.replace('/login')
      return
    }

    window.document.title = 'FinSports-Player Registration'
    this.fetchData()
  },
  methods: {
    fetchData() {
      getProject(this.project_code).then((res) => {
        this.project = res.data.data
      })
      getAccountApplication(this.account_id).then((res) => {
        this.events = res.data
      })
      getAccount(this.account_id).then((res) => {
        this.account = res.data.data
      })
    },
    submitData() {

      if (!this.form.student_lastName
          || !this.form.student_givenName
          || !this.form.student_lastName_pinyin
          || !this.form.student_givenName_pinyin
          || !this.form.student_mobile
          || !this.form.student_email
          || !this.form.student_guardian_first_mobile
          || !this.form.student_guardian_first_email
      ) {
        this.$message.error('Please fill the form')
        return
      }
      if (this.form.student_mobile == this.form.student_guardian_first_mobile) {
        this.$message.error('Student mobile cannot be the same to the guardian\'s mobile!')
        return;
      }
      let params = {
        project_code: this.project_code,
        account_id: this.account_id,
        student_lastName: this.form.student_lastName,
        student_givenName: this.form.student_givenName,
        student_lastName_pinyin: this.form.student_lastName_pinyin,
        student_givenName_pinyin: this.form.student_givenName_pinyin,
        student_gender: this.form.student_gender,
        student_graduation: this.form.student_graduation,
        student_mobile: this.form.student_mobile,
        student_email: this.form.student_email,
        student_guardian_first_mobile: this.form.student_guardian_first_mobile,
        student_guardian_first_email: this.form.student_guardian_first_email,
        application_event: this.form.application_event,
      }
      addNewApplication(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.$message.success('Registration Success')
          if (result.new_student_status) {
            this.$router.replace('/home/panel/passwords')
          } else {
            this.$router.replace('/login')
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
  }
}
</script>

<style scoped lang="less">

.apply-container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/root_bg.png");
  background-size: cover;
  background-position: 100%;
  background-color: transparent;
  overflow-y: scroll;
}

.apply-data-container {
  background-color: white;
  max-width: 1360px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0px auto;

  .data-show {
    padding: 40px;
  }

  .item-width {
    max-width: 50%;
  }

  .title {
    font-size: 24px;
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #182958;
  }

  .btn_submit {
    margin-top: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 160px;
    height: 40px;
    line-height: 40px;
    background: #FE7702;
    border-radius: 2px 2px 2px 2px;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
  }

  .checkbox {
    width: 14px;
    height: 14px;

  }
}

</style>
