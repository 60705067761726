<template>
  <div>
    <div class="container">
      <div class="flex-column-left" style="flex: 1;">
        <p class="sub_title">Current Period Salary Cash Inflow</p>
        <p class="price">¥{{ formatPrice(salary) }}</p>
      </div>
      <div style="width: 20px;"></div>
      <div class="flex-column-left reason" style="flex: 1;cursor: pointer" @click="showReason">
        <p class="sub_title">Reasons for change</p>
        <p class="price">{{ salaryReason }}</p>
      </div>
      <div style="width: 20px;"></div>
      <div class="flex-column-left" style="flex: 1;">
        <p class="sub_title">Total Assets</p>
        <p class="price">¥{{ formatPrice(total_assets) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'
import {addCommaToThousands} from "../utils/NumberUtils";

export default {
  name: "CurrentInvestmentAssets",
  props: {
    total_assets: {
      type: String,
      default: ''
    },
    salary: {
      type: String,
      default: ''
    },
    salaryReason: {
      type: String,
      default: ''
    }
  },
  methods: {
    formatPrice(price) {
      return addCommaToThousands(price)
    },
    showReason() {
      this.$alert(this.salaryReason, '提示', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
    }
  }
}
</script>

<style scoped>

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
  background-image: url("../assets/current_devision_bg.png");
}

.sub_title {
  color: white;
  font-size: 20px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.reason {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.price {
  margin-top: 5px;
  color: white;
  font-size: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

</style>